import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import {Link} from 'gatsby';

import {CircleColor, ColorPickerStyle} from './styles';
import {
  findVariantByOptionValue,
  getOptionValuesByType,
} from '../../../utils/productHelper';

export const ColorPicker = ({
  product,
  isLoading,
  onChangeColor,
  productId,
  ...props
}) => {
  const colors = useMemo(
    () => getOptionValuesByType(product, 'color'),
    [product]
  );

  const handleHoverColor = optionValue => {
    const variant = findVariantByOptionValue(product.variants, optionValue);
    onChangeColor(variant);
  };
  const handleHoverReset = () => {
    setTimeout(() => {
      return onChangeColor(product.master);
    }, 300);
  };
  if (!colors.length && !isLoading && !ColorPicker) return null;
  return (
    <ColorPickerStyle {...props}>
      {colors.length > 7 ? (
        <>
          {colors.slice(0, 7).map((color, index) => (
            <CircleColor
              color={color}
              key={index}
              onMouseOver={() => handleHoverColor(color)}
              onMouseOut={handleHoverReset}
              title={color.presentation}
            />
          ))}
          <Link to={`/products/${productId}`}>
            <div className="circle-more-colors">
              <Text level="4" as="h4">
                +
              </Text>
            </div>
          </Link>
        </>
      ) : (
        colors.map((color, index) => (
          <CircleColor
            color={color}
            key={index}
            onMouseOver={() => handleHoverColor(color)}
            onMouseOut={handleHoverReset}
            title={color.presentation}
          />
        ))
      )}
    </ColorPickerStyle>
  );
};
export default ColorPicker;

ColorPicker.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    master: PropTypes.array,
    variants: PropTypes.array,
    product_url: PropTypes.string,
    images: PropTypes.shape({
      mini_url: PropTypes.string,
      small_url: PropTypes.string,
    }),
  }),
  onChangeColor: PropTypes.func,
  isLoading: PropTypes.bool,
  productId: PropTypes.string,
};
