import React from 'react';
import StyledSlider from './styles';
import PropTypes from 'prop-types';

const SliderCollections = ({image, tag}) => {
  return <StyledSlider tag={tag} image={image} />;
};

export default SliderCollections;

SliderCollections.propTypes = {
  image: PropTypes.element,
  tag: PropTypes.string,
};
