import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';
import {Heading} from 'styled-typography';
import Card from '../core/Card';
import {MightAlsoLikeStyle} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import {fetchProductsWithFilter} from '../../API/makers/products';

const MightAlsoLike = () => {
  const logoEmpty = useImgixImage('/images/logo-empty.png');
  const perPage = 'per_page=4';
  const {products, isLoading} = fetchProductsWithFilter(perPage);
  const isActive = products.filter(item => item.active === true);
  return (
    <MightAlsoLikeStyle>
      <Row>
        <Col>
          <Heading as="h3" level="4">
            You might also like
          </Heading>
        </Col>
      </Row>
      <Row>
        {!products.length && !isLoading ? (
          <Col align="center">
            <img src={logoEmpty} className="empty-state"></img>
          </Col>
        ) : (
          isActive.map(product => (
            <Col
              xs={2}
              sm={4}
              md={2}
              lg={3}
              xl={3}
              align="center"
              key={product.id}
            >
              <Card
                productId={product.id}
                img={
                  product.master.images[0] &&
                  product.master.images[0].product_url
                }
                productName={product.name}
                product={product}
                Price={product.master.display_price}
              ></Card>
            </Col>
          ))
        )}
      </Row>
    </MightAlsoLikeStyle>
  );
};

export default MightAlsoLike;
