import {navigate} from 'gatsby';
import {logout} from '../../services/authentication';
import {clearCurrentOrder} from '../../services/temporalOrder';

const DEFAULT_HEADER = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
};

const fetcher = async url => {
  const res = await fetch(url, {
    headers: {...DEFAULT_HEADER},
  });

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const post = (url, opts = {headers: {}}) => {
  return fetch(url, {
    method: 'POST',
    headers: {...DEFAULT_HEADER, ...opts.headers},
    ...opts,
  });
};

export const rawPost = (url, opts = {headers: {}}) => {
  return fetch(url, {
    method: 'POST',
    ...opts,
  });
};

export const patch = (url, opts = {headers: []}) => {
  return fetch(url, {
    method: 'PATCH',
    headers: {...DEFAULT_HEADER, ...opts.headers},
    ...opts,
  }).then(data => {
    return data;
  });
};

export const put = (url, opts = {headers: []}) => {
  return fetch(url, {
    method: 'PUT',
    headers: {...DEFAULT_HEADER, ...opts.headers},
    ...opts,
  });
};

export const get = (url, opts = {headers: {}}) => {
  return fetch(url, {
    method: 'GET',
    headers: {...DEFAULT_HEADER, ...opts.headers},
    ...opts,
  }).then(data => {
    if (data.status === 401) {
      logout(() => {
        clearCurrentOrder();
        navigate('/');
      });
    }
    return data;
  });
};

export const destroy = (url, opts = {headers: {}}) => {
  return fetch(url, {
    method: 'DELETE',
    headers: {...DEFAULT_HEADER, ...opts.headers},
    ...opts,
  });
};

export const serializeParams = function (params) {
  var queryString = [];
  for (var keyParams in params)
    if (Object.prototype.hasOwnProperty.call(params, keyParams)) {
      queryString.push(
        encodeURIComponent(keyParams) +
          '=' +
          encodeURIComponent(params[keyParams])
      );
    }
  return queryString.join('&');
};

export default fetcher;
