export const MAKERS_URL = process.env['GATSBY_MAKERS_URL'];
export const MAKERS_PRODUCTS_URL = process.env['GATSBY_PRODUCT_URL'];
const MAKERS_TAXONS_CATEGORY_ID = process.env['GATSBY_CATEGORY_ID'];
export const taxons_url = `${MAKERS_URL}/api/taxonomies/${MAKERS_TAXONS_CATEGORY_ID}/taxons/`;
export const taxons_category = `${MAKERS_URL}/api/taxons`;
export const products_url = `${MAKERS_URL}/api/products/`;
export const option_types_url = `${MAKERS_URL}/api/option_types/`;
export const variants_url = `${MAKERS_URL}/api/variants?`;
export const register_url = `${MAKERS_URL}/makers/api/auth/`;
export const contact_url = `${MAKERS_URL}/makers/api/contact_us`;
export const countries_url = `${MAKERS_URL}/api/countries/`;
export const login_url = `${MAKERS_URL}/makers/api/auth/login`;
export const orders_url = `${MAKERS_URL}/api/orders`;
export const account_url = `${MAKERS_URL}/api/users`;
export const email_url = `${MAKERS_URL}/makers/api/auth/forgot-password`;
export const password_url = `${MAKERS_URL}/makers/api/auth/reset-password`;
export const checkouts_url = `${MAKERS_URL}/api/checkouts`;
export const setup_intent_url = `${MAKERS_URL}/makers/api/setup_intent`;
export const payment_url = `${MAKERS_URL}/orders`;
export const favorite_url = `${MAKERS_URL}/makers/api/user_favorites`;
export const banner_url = `${MAKERS_URL}/makers/api/banners`;
export const lookbook_url = `${MAKERS_URL}/makers/api/look_books`;
export const popup_url = `${MAKERS_URL}/makers/api/popups`;
export const magic_link_url = `${MAKERS_URL}/makers/api/auth/magic_link_login`;
