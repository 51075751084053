import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const StyledSlider = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;
  .card-wrapper-one {
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: white;
    margin: auto;
    width: 312px;
    height: 279px;
    border-radius: 20px;
    ${props => config(props).media['sm']`
      width: 400px;
      height: 579px;
    `}
    ${props => config(props).media['md']`
      width: 400px;
      margin: 0px;
    `}
    ${props => config(props).media['lg']`
      width: 450px;
      margin-left: 20px;
    `}
    cursor: pointer;
    .slick-slider {
      margin: 0px;
      width: 100%;
      img {
        width: 100%;
      }
      padding: 0px !important;
      .slick-list {
        align-items: center;
        .slick-track {
          padding: 0px !important;
          margin: 0px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          .slick-current {
            div {
              width: 100%;
            }
          }
        }
        .slick-slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      button.slick-next,
      button.slick-prev {
        &::before {
          display: flex;
          background: url('${props => props.arrow}') center center no-repeat;
          transform: rotate(180deg);
          content: ' ';
          ${props => config(props).media['sm']`
            width: 15px;
            height: 27px;
          `}
        }
      }
      button.slick-next {
        &::before {
          transform: rotate(0deg);
        }
      }
      .slick-dots {
        li button:before {
          font-size: 18px;
          width: 10px;
          height: 10px;
          content: '';
          border: 0.5px solid black;
          border-radius: 50%;
        }
        li.slick-active button:before {
          font-size: 10px;
          opacity: 1;
          content: '';
          background-color: black;
        }
      }
    }
  }
  > div {
    height: 259px;
    ${props => config(props).media['sm']`
      height: 579px;
    `}
    .card-wrapper-two {
      display: none;
      ${props => config(props).media['md']`
        display: flex;
        align-items: center;
      `}
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        /* height: 100%; */
        width: 100%;
      }
      .slick-slider {
        height: 473px;
        .slick-active {
          > div {
            width: 100% !important;
          }
        }
        .slick-list {
          height: 473px !important;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          width: 100px;
          ${props => config(props).media['md']`
            width: 100px;
            `}
          ${props => config(props).media['lg']`
            width: 115px;
          `}
          .slick-track {
            display: flex;
            flex-direction: column;
            .slick-current {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              > div {
                width: 100% !important;
              }
            }
            opacity: 1;
            transform: translate3d(0px, -540px, 0px);
            padding: 0px 0px;
            height: 12100px !important;
          }
          .slick-slide {
            overflow: hidden;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            height: 136px;
            border-radius: 10px;
            margin: 10.5px 0;
          }
        }
      }
    }
  }
  .Bottom {
    display: none;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 43px;
    border-radius: 15px;
    background-color: white;
    cursor: pointer;
    ${props => config(props).media['md']`
      display: flex;
      width: 100px;
    `}
    ${props => config(props).media['lg']`
      width: 115px;
    `}

    :hover {
      background-color: solid 1px ${({theme}) => theme.colors.primary2};
    }
  }

  .img img {
    width: 100%;
    :hover {
      cursor: none;
    }
  }
  .image-small {
    display: flex;
    width: 100%;
  }
`;
