import React from 'react';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import {useGlobalContext} from '../../../context';
import {ModalStyle} from './styles';

const ModalSize = () => {
  const {setModalIsOpen} = useGlobalContext();

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const sizesRun = [
    {
      sizeCode: 'S',
      sizes: [5, 6, 7, 8, 9, 10],
      pairs: [1, 2, 3, 3, 2, 1],
      total: 12,
    },
    {
      sizeCode: 'L',
      sizes: [7, 8, 9, 10, 11],
      pairs: [2, 2, 3, 3, 2],
      total: 12,
    },
    {
      sizeCode: 'O',
      sizes: [6, 7, 8, 9, 10],
      pairs: [2, 2, 3, 3, 2],
      total: 12,
    },
    {
      sizeCode: 'A',
      sizes: [5, 6, 7, 8, 9, 10],
      pairs: [2, 4, 5, 4, 2, 1],
      total: 18,
    },
    {
      sizeCode: 'B',
      sizes: [7, 8, 9, 10, 11],
      pairs: [2, 4, 5, 5, 2],
      total: 18,
    },
  ];
  return (
    <ModalStyle>
      <div className="modal-head" onClick={closeModal}>
        <Heading as="h3" level="4">
          Makers Shoes Size Runs
        </Heading>
      </div>
      {sizesRun &&
        sizesRun.map((item, index) => (
          <div className="info-wrap" key={index}>
            <div className="sizes-code-wrap">
              <Heading as="h3" level="2">
                {`${item.sizeCode}`}
              </Heading>
            </div>
            <div className="flex-column">
              <div className="sizes-pairs-wrap">
                {item.sizes.map((size, index) => (
                  <div key={index} className="sizes-wrap">
                    <Heading as="h4" lvl="3">
                      {`${size}`}
                    </Heading>
                  </div>
                ))}
              </div>
              <div className="sizes-pairs-wrap">
                {item.pairs.map((pair, index) => (
                  <div key={index} className="pairs-wrap">
                    <Text level="5">{`${pair}`}</Text>
                  </div>
                ))}
              </div>
            </div>
            <div className="total-wrap">
              <div>
                <Heading as="h4" level="5">
                  TOTAL
                </Heading>
              </div>
              <div>
                <Text level="5">{`${item.total}`}</Text>
              </div>
            </div>
          </div>
        ))}
    </ModalStyle>
  );
};

export default ModalSize;

ModalSize.propTypes = {
  subtitle: PropTypes.string,
};
