import useSWR from 'swr';

import fetcher from './fetcher';
import {taxons_url, taxons_category} from './urls';

export const fetchTaxons = () => {
  const {data, error} = useSWR(taxons_url, fetcher);
  return {
    taxons: (data && data.taxons) || [],
    error: error,
    isLoading: !data && !error,
  };
};

export const fetchTaxonsCategory = () => {
  const {data, error} = useSWR(taxons_category, fetcher);
  return {
    taxonsCategory: (data && data.taxons) || [],
    error: error,
    isLoading: !data && !error,
  };
};
