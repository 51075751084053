import useSWR from 'swr';

import {products_url} from './urls';
import fetcher, {serializeParams} from './fetcher';

export const fetchProducts = filters => {
  const {data, error, isValidating} = useSWR(
    `${products_url}?${serializeParams(filters)}`,
    fetcher
  );
  return {
    products: (data && data.products) || [],
    data: (data && data) || [],
    error: error,
    isLoading: !data && !error,
    isValidating,
  };
};

export const fetchProductIds = (productIds = []) => {
  const queryParams = new URLSearchParams();
  productIds.forEach(id => queryParams.append('q[id_in][]', id));
  const hasProductIds = productIds.length > 0;
  const {data, error} = useSWR(
    hasProductIds ? `${products_url}?${queryParams}` : null,
    fetcher
  );

  if (!hasProductIds) {
    return {
      isLoading: false,
      products: [],
      error: true,
    };
  }

  return {
    products: (data && data.products) || [],
    error: error,
    isLoading: !data && !error,
  };
};

export const fetchProduct = id => {
  const {data, error} = useSWR(`${products_url}${id}`, fetcher);
  return {
    product: (data && data) || {variants: []},
    error: error,
    isLoading: !data && !error,
  };
};

export const fetchProductsWithFilter = filter => {
  const key = filter ? `${products_url}?${filter}` : null;
  const {data, error} = useSWR(key, fetcher);
  return {
    products: (data && data.products) || [],
    currentPage: (data && data.current_page) || 0,
    pages: (data && data.pages) || 0,
    count: (data && data.count) || 0,
    perPage: (data && data.per_page) || 2,
    error: error,
    isLoading: !data && !error,
  };
};
