import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const StyledInput = styled.div`
  z-index: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  position: relative;
  .react-autosuggest__suggestions-container {
    background-color: white;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08),
      0px 1px 2px rgba(0, 0, 0, 0.08);
    z-index: 5;
    margin: 20px 0px;
    list-style: none;
    position: absolute;
  }
  .react-autosuggest__suggestion {
    margin: 20px 0px;
    list-style: none;
  }
  .container {
    display: flex;
    text-transform: initial;
    flex-direction: column;
    position: relative;
    .iconSearch {
      position: absolute;
      right: 20px;
      top: 13px;
    }
    .react-autosuggest__input {
      width: 22rem;
      height: 2.875em;
      border: solid 1px ${({theme}) => theme.colors.secondary2};
      border-radius: 8px;
      font-size: 0.884rem;
      padding-left: 10px;
      ${props => config(props).media['md']`
                width: 27rem;
                `}

      :focus {
        outline: none;
        border: solid 1px ${({theme}) => theme.colors.primary2};
      }
    }
  }
`;

export const ContainerSuggestions = styled.div`
  & > div:last-child h2 {
    border-bottom: none;
  }
  h1 {
    color: ${({theme}) => theme.colors.secondary};
    text-transform: uppercase;
    margin: 16px 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
  }
  h2 {
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
    margin: 15px 20px;
    padding-bottom: 15px;
    cursor: pointer;
    :hover {
      font-weight: bold;
    }
  }
`;
export const ContainerProducts = styled.div`
  width: 100%;
  h1 {
    color: ${({theme}) => theme.colors.secondary};
    text-transform: uppercase;
    margin: 0px 20px 15px;
  }

  & > div:last-child .line {
    display: none;
  }

  .container-product {
    display: flex;
    align-items: center;
    cursor: pointer;
    :hover {
      background-color: ${({theme}) => theme.colors.secondary2};
    }

    .list-product {
      width: 100%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin: auto;

      h2 {
        margin: 0px 12px 0px;
      }
      h3 {
        margin: 0px 12px 15px;
        color: ${({theme}) => theme.colors.secondary};
      }
      h4 {
        margin: 0px 12px 0px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    .image-container {
      display: flex;
      background-color: white;
      width: 5.525em;
      height: 4.025em;
      margin-bottom: 15px;
      margin-left: 20px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      border: 1.5px solid ${({theme}) => theme.colors.secondary2};
      .img {
        justify-content: center;
      }
    }

    .line {
      border-top: 1px solid ${({theme}) => theme.colors.secondary2};
      padding: 0;
      width: 91%;
      display: flex;
      position: absolute;
      bottom: 80px;
      left: 74px;
      justify-content: center;
      margin: 10px auto;
      margin-left: -13%;
      ${props => config(props).media['md']`
                      bottom: 97px;

  `}
    }
  }
  h5 {
    color: ${({theme}) => theme.colors.secondary};
  }
  .skeleton-information {
    width: 250px;
    height: 70px;
    margin-bottom: 10px;
    margin-left: 10px;
    ${props => config(props).media['md']`
    width: 300px;
    height: 70px;
                
     `}
  }
  .skeleton-image {
    width: 70px;
    height: 70px;
    ${props => config(props).media['md']`
    width: 100px;
    height: 70px;        
  `}
  }
`;
