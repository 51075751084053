import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export default styled.div`
  height: 350px;
  background: url(${({image}) => image}) no-repeat;
  background-size: cover;
  background-position-x: center;
  ${props => config(props).media['sm']`
    height: 450px;
  `}
  h2 {
    justify-content: flex-start;
    width: 286px;
    margin-left: 20px;
    padding-top: 97px;
    font-size: 2.275em;
    ${props => config(props).media['sm']`
      font-size: 2.075em;
      margin-left: 50px;
      width: 486px;
    `}
  }

  .button-wrapper {
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    ${props => config(props).media['sm']`
      margin-top: 20px;
      margin-left: 60px;
    `}
    p {
      letter-spacing: 0.1em;
    }
  }

  > p {
    display: flex;
    justify-content: flex-start;
    width: 325px;
    height: 44px;
    margin-left: 30px;
    ${props => config(props).media['sm']`
      margin-top: 20px;
      margin-left: 60px;
    `}
    margin-bottom: 60px;
  }
`;
