import React from 'react';
import {navigate} from 'gatsby';
import SliderSlick from 'react-slick';
import {Hidden} from 'react-awesome-styled-grid';

import {CollectionsStyled} from './styles';
import SliderCollections from '../core/SliderCollections';
import {generateImageUrl} from '../../utils/productHelper';
import {fetchBanners} from '../../API/makers/adminBanners';
import SkeletonSlider from '../core/Slider/SkeletonSlider';

export const SliderComponent = () => {
  const selecItemBanner = 'home-collections-banner';
  const {banners} = fetchBanners(selecItemBanner);

  const settings = {
    className: 'center',
    dots: true,
    infinite: true,
    centerPadding: '40px',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    rtl: false,
    arrows: true,
  };

  return (
    <CollectionsStyled>
      <link
        rel="stylesheet"
        type="text/css"
        // charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Hidden xs>
        <SliderSlick {...settings}>
          {banners.length ? (
            banners.map(banner => (
              <SliderCollections
                key={banner.id}
                tag="collections-banner"
                image={generateImageUrl(banner.image_url)}
                onClickImage={() => navigate(`${banner.link}`)}
              />
            ))
          ) : (
            <SkeletonSlider tag="collections-banner" />
          )}
        </SliderSlick>
      </Hidden>
      <Hidden sm md lg xl>
        <SliderSlick {...settings}>
          {banners.length ? (
            banners.map(banner => (
              <SliderCollections
                key={banner.id}
                tag="collections-banner"
                image={generateImageUrl(banner.mobile_url)}
                onClickImage={() => navigate(`${banner.link}`)}
              />
            ))
          ) : (
            <SkeletonSlider tag="collections-banner" />
          )}
        </SliderSlick>
      </Hidden>
    </CollectionsStyled>
  );
};

export default SliderComponent;
