import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {StyledInput} from './styles';
import Autocomplete from './AutoComplete';
import useImgixImage from '../../../hooks/useImgixImage';
import {fetchProducts} from '../../../API/makers/products';

const InputSearch = () => {
  const imgEmpty = useImgixImage('/images/MoreProducts/jackeline-gray.png');
  const iconSearch = useImgixImage('/images/search.svg');
  const [toSearch, setToSearch] = useState('');
  const filters = {
    'q[taxons_name_or_description_or_variants_option_values_presentation_or_meta_keywords_or_name_start]':
      toSearch,
  };
  const {products, isValidating, isLoading} = fetchProducts(
    toSearch.length ? filters : false
  );
  const isActive = products.filter(item => item.active === true);

  const handleChange = value => {
    if (value.length > 2) {
      setToSearch(value);
    }
  };

  const stockStatus = product => {
    const isBackorderable = product.variants.some(
      item => item.is_backorderable === true
    );
    const isInStock = product.variants.some(item => item.total_on_hand >= 1);
    const stock = isInStock
      ? 'IN STOCK'
      : isBackorderable
      ? product.available_on
        ? `AVAILABLE ON: ${moment(product?.available_on).format('MMM DD/YYYY')}`
        : 'PRE-ORDER'
      : 'OUT OF STOCK';
    return stock;
  };

  return (
    <StyledInput searchIcon={iconSearch}>
      <div className="container">
        <div className="iconSearch">
          <img src={iconSearch}></img>
        </div>
        <Autocomplete
          onChange={handleChange}
          suggestions={[
            {
              title: 'Products',
              suggestions:
                !isLoading &&
                isActive.map(product => ({
                  name: product.name,
                  price: product.display_price,
                  images: product.master.images[0]
                    ? product.master.images[0]?.mini_url
                    : imgEmpty,
                  inStock: stockStatus(product),
                  id: product.id,
                })),
            },
          ]}
          isValidating={isValidating}
        />
      </div>
    </StyledInput>
  );
};

export default InputSearch;

InputSearch.propTypes = {
  products: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  isValidating: PropTypes.bool,
};
