import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import ProductResults from './ProductResults';
import {navigate} from '@reach/router';
import {useGlobalContext} from '../../../context';

const renderSuggestion = suggestion => {
  return suggestion.title === 'Products' ? (
    <ProductResults
      title={suggestion.title}
      products={suggestion.suggestions}
    />
  ) : (
    <ProductResults />
  );
};

const Autocomplete = ({suggestions, onChange, isValidating}) => {
  const [value, setValue] = useState('');
  const {setIsLoading} = useGlobalContext();
  const getSuggestionValue = suggestion => suggestion.title;

  const inputProps = {
    placeholder: 'Search the best shoes styles here',
    value,
    onKeyDown: ({code, target}) => {
      if (code === 'Enter' && target.value.length) {
        navigate(`/search-page?page=1&per_page=12&search=${target.value}`);
        setValue('');
      }
    },
    onChange: (event, {newValue}) => {
      setValue(newValue);
      onChange(newValue);
    },
  };
  const onSuggestionsFetchRequested = ({value}) => {
    ({
      suggestions: getSuggestionValue(value),
    });
  };

  setIsLoading(isValidating);
  return (
    <Autosuggest
      getSuggestionValue={getSuggestionValue}
      suggestions={suggestions}
      shouldRenderSuggestions={suggestionValue => {
        if (suggestionValue.title) {
          return suggestionValue.title.trim().length > 2;
        } else {
          return suggestionValue.trim().length > 2;
        }
      }}
      onSuggestionSelected={() => {
        setValue('');
      }}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.array,
  onChange: PropTypes.func,
  isValidating: PropTypes.bool,
};

export default Autocomplete;
