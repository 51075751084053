import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  flex-direction: column;
  background-color: white;
  padding: 10px 20px;
  ${props => config(props).media['md']`
    padding: 10px 60px;
  `}

  h3 {
    color: ${({theme}) => theme.colors.primary2};
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .head {
    margin-top: 40px;
    margin-bottom: 20px;
    ${props => config(props).media['md']`
      margin-top: 60px;
    `}
    .logo {
      width: 127.5px;
    }
    p {
      letter-spacing: -0.4px;
      margin-top: 40px;
      margin-bottom: 0px;
      font-size: ${({theme}) => theme.typography.fontSizes[3]};
      color: black;
      ${props => config(props).media['sm']`
        letter-spacing: 0.1px;
        color: ${({theme}) => theme.colors.secondary};
        font-size: ${({theme}) => theme.typography.fontSizes[4]};
        margin-top: 6px;
        margin-bottom: 20px;
      `}
      ${props => config(props).media['lg']`
        margin-bottom: 40px;
      `}
    }
    .makers-monogram {
      margin-right: 0px;
      height: 41px;
      ${props => config(props).media['sm']`
        margin-right: 36px;
        height: 64px;
      `}
    }
  }

  .container-info {
    margin-top: 0px;
    margin-bottom: 20px;
    ${props => config(props).media['sm']`
      margin-bottom: 40px;
    `}
    ${props => config(props).media['lg']`
      margin-bottom: 50px;
    `}
    h3 {
      letter-spacing: 1.5px;
      margin-top: 40px;
      margin-bottom: 12px;
      color: ${({theme}) => theme.colors.primary2};
      font-size: ${({theme}) => theme.typography.fontSizes[4]};
      ${props => config(props).media['sm']`
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
        margin-top: 5px;
      margin-bottom: 20px;
      `}
    }
    li {
      list-style: none;
      font-weight: 400;
      line-height: 23px;
      font-size: ${({theme}) => theme.typography.fontSizes[3]};
      ${props => config(props).media['sm']`
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
      margin-bottom: 10px;
      line-height: 13px;
      `}

      &:hover {
        cursor: pointer;
        color: ${({theme}) => theme.colors.primary3};
      }
      &::active {
        color: ${({theme}) => theme.colors.primary2};
      }
    }
  }

  .div-line {
    display: none;
    ${props => config(props).media['sm']`
    display: flex;
    margin-top: -30px;
    `}
    img {
      height: 100%;
      width: 1px;
    }
  }

  .contact {
    margin-left: 0px;
    ${props => config(props).media['sm']`
      margin-left: 23px;
    `}
    p {
      letter-spacing: 0px;
      margin-top: -2px;
      color: ${({theme}) => theme.colors.secondary};
      font-size: ${({theme}) => theme.typography.fontSizes[3]};
      ${props => config(props).media['sm']`
      letter-spacing: 0.5px;
      margin-top: -3px;
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
      `}
    }
    h4 {
      margin-top: 4px;
      margin-bottom: 20px;
      font-size: ${({theme}) => theme.typography.fontSizes[3]};
      ${props => config(props).media['sm']`
      margin-bottom: 22px;

      margin-top: 6px;
      font-size: ${({theme}) => theme.typography.fontSizes[4]};
      `}
    }
  }

  .follow-us {
    margin-top: 0px;
    margin-bottom: 0px;
    ${props => config(props).media['sm']`
      margin-bottom: 0px;
      margin-top: 0px;
    `}
    div {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      h3 {
        margin-right: 20px;
        ${props => config(props).media['sm']`
        margin-right: 0px;
        `}
      }
      p {
        letter-spacing: -0.4px;
        font-size: ${({theme}) => theme.typography.fontSizes[3]};
        ${props => config(props).media['sm']`
        font-size: ${({theme}) => theme.typography.fontSizes[5]};
        `}
        &:hover {
          cursor: pointer;
          color: ${({theme}) => theme.colors.primary3};
        }
      }
      img {
        cursor: pointer;
        svg {
          &:hover {
            path {
              fill: ${({theme}) => theme.colors.primary3};
            }
          }
        }
      }
    }
    img {
      margin-right: 10px;
      height: 14px;
    }
  }

  .copyright {
    border-top: 1px solid ${({theme}) => theme.colors.secondary2};
    padding: 20px 0px 0px 0px;
    margin-bottom: 22px;
    p {
      color: ${({theme}) => theme.colors.secondary};
      font-size: ${({theme}) => theme.typography.fontSizes[4]};
      ${props => config(props).media['md']`
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
      `}
    }
  }
`;
