import {favorite_url} from './urls';
import {post, get, destroy} from './fetcher';

export const addFavorites = async ({user, product_id}, callback = () => {}) => {
  const response = await post(favorite_url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      product_id,
    }),
  });
  if (!response.ok) {
    return;
  }
  const addFavorite = await response.json();
  callback(addFavorite);
};

export const getFavorites = async (user, callback = () => {}) => {
  const response = await get(favorite_url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });
  if (!response.ok) {
    return;
  }
  const getFavorite = await response.json();
  callback(getFavorite);
};

export const deleteFavorites = async ({user, id}, callback = () => {}) => {
  const response = await destroy(`${favorite_url}/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });
  if (!response.ok) {
    return;
  }
  callback();
};
