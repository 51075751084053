import React from 'react';
import {StyledFormInput} from './styles';
import PropTypes from 'prop-types';

export const getError = (errors, field) => {
  const error = (errors || []).find(({context: {key}}) => key === field);
  return error ? error.message : '';
};

const InputForm = ({
  errors,
  field,
  type,
  placeholder,
  value,
  onChange,
  name,
}) => {
  return (
    <StyledFormInput>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
      />
      <div className="error-wrapper">
        <span>{getError(errors, field)}</span>
      </div>
    </StyledFormInput>
  );
};

export default InputForm;

InputForm.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  field: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.array,
};
