import React from 'react';
import {Text} from 'styled-typography';
import PropTypes from 'prop-types';
import {DropDownListItem} from './styles';
import {useGlobalContext} from '../../../context';

const DropDownItem = ({
  id,
  index,
  title,
  itemProps,
  miniature,
  highlightedIndex,
  colorTitle,
  colorSubtitle,
  onClick,
  priceDisplay,
  onStock,
}) => {
  const {userLocal} = useGlobalContext();

  return (
    <DropDownListItem
      ishighlighted={highlightedIndex === index}
      key={`${id}${index}`}
      {...itemProps}
    >
      {onStock ? (
        <div className="dropdown-list-wrapper" onClick={onClick}>
          <div className="dropdown-list-image">{miniature}</div>
          <div>
            <div className="dropdown-list-info">
              <Text level="5" color={colorTitle}>
                {title}
              </Text>
              <Text level="5" color={colorTitle}>
                {userLocal && priceDisplay}
              </Text>
            </div>
            <div className="dropdown-list-stock">
              <Text level="6" color={colorSubtitle}>
                {onStock}
              </Text>
            </div>
          </div>
        </div>
      ) : (
        <div className="dropdownlistwrapper" onClick={onClick}>
          <div>{miniature}</div>
          <div>
            <Text level="5" color={colorTitle}>
              {title}
            </Text>
          </div>
        </div>
      )}
    </DropDownListItem>
  );
};

export default DropDownItem;

DropDownItem.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  title: PropTypes.string,
  colorTitle: PropTypes.string,
  colorSubtitle: PropTypes.string,
  subtitle: PropTypes.string,
  itemProps: PropTypes.object,
  miniature: PropTypes.node,
  highlightedIndex: PropTypes.bool,
  onClick: PropTypes.object,
  priceDisplay: PropTypes.string,
  onStock: PropTypes.string,
};
