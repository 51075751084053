import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const MenuStyle = styled.div`
  position: fixed;
  top: 140px;
  z-index: 1;
  background: white;
  width: 100%;
  border-bottom: 1px solid #eff0f1;
  font-size: 15px;
  letter-spacing: 0.1em;
  left: ${({isOpen}) => (isOpen ? '0' : '-100%')};
  transition: all 0.3s ease-in, background-color 0s;
  .wrapper-mobile {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
  }
  .menu {
    a {
      width: 100%;
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:after {
        font-size: 21px;
        content: '+';
        display: inline-block;
        &:active {
          transform: rotate(45deg);
        }
      }
    }
  }
  h3 {
    margin-bottom: 20px;
    :first-child {
      margin-top: 20px;
    }
    ${props => config(props).media['md']`
      margin-right: 20px;
      margin-top: 25px;
      :first-child {
        margin-top: 25px;
      }
    `}
  }

  .ContainerMenu {
    flex-direction: column;
    margin: 20px auto;
    width: 100%;
    display: none;
    ${props => config(props).media['md']`
     display:flex;
  `}
  }
  .ContainerImage {
    display: none;
    margin: 20px auto 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;

    img {
      width: 90%;
      border-radius: 10px;
      position: relative;
      left: 0px;
      bottom: 0px;
      display: none;
      ${props => config(props).media['xs']`
        display: flex;
      `}
    }
    ${props => config(props).media['xs']`
      display: flex;
    `}
  }
  .szh-menu {
    display: none;
    width: 900px;
    margin-top: 8px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08),
      0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    ${props => config(props).media['md']`
     width: 400px;
     display: flex;
  `}
    .szh-menu__item {
      display: none;
      align-items: center;
      padding: 0.28em 1.5rem;
      ${props => config(props).media['md']`
     
     display: flex;
  `}
      :active {
        background-color: ${({theme}) => theme.colors.secondary};
      }
    }
    h2 {
      text-transform: initial;
      color: ${({theme}) => theme.colors.primary};
      font-weight: 500;
    }
  }
  .ContainerBrandMenu {
    .szh-menu {
      ${props => config(props).media['md']`
     width: 100px;
     display: flex;
  `}
      .szh-menu__item {
        width: 100%;
        padding: 0px;
      }
    }
  }
  .szh-menu__item {
    display: flex;
    flex-direction: column;
  }
  .LinkMenu {
    color: #000000;
    text-transform: capitalize;
  }
  a {
    color: #000000;
    cursor: pointer;
    margin-left: 20px;
    ${props => config(props).media['md']`
    margin-left: 0px;
    margin-top: 0px;
    `}
    :hover {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
  ${props => config(props).media['md']`
    position: unset;
    width: 94%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    a {
      color: #000000;
      cursor: pointer;
      :hover{
      color: ${({theme}) => theme.colors.primary2};
      }
    }
    div {
      &:last-child a {
        margin-right: 0px;
      }
    }
 `}
  .empty-state {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
  }
`;

export const ContainerLoginStyle = styled.div`
  display: flex;
  z-index: 3;
  background-color: white;
  height: calc(100% - 100px);
  padding-top: 0px;
  border-top: 1px solid ${({theme}) => theme.colors.secondary3};
  position: fixed;
  width: 100%;
  flex-direction: column;
  .cart-favorite {
    cursor: pointer;
    text-transform: initial;
    margin-bottom: 15px;
    font-weight: 600;
    :hover {
      color: ${({theme}) => theme.colors.primary2};
    }
    :first-child {
      padding-top: 20px;
    }
  }
  > div {
    h3 {
      cursor: pointer;
      text-transform: initial;
      padding-left: 20px;
      font-weight: 500;
      padding-bottom: 10px;
      :hover {
        color: ${({theme}) => theme.colors.primary2};
      }
    }
  }
  ${props => config(props).media['md']`
    display:none;
  `}
`;

export const MenuItems = styled.div`
  display: ${({open}) => (open ? 'flex' : 'none')};
  width: 100%;
  flex-direction: column;
  ul {
    margin-left: 20px;
    li {
      font-weight: 600;
      list-style: none;
      margin: 30px 0;
    }
  }
  ${props => config(props).media['md']`
    ul {
    margin-left: 0px;
    li {
      font-weight: 600;
      list-style: none;
      margin: 0;
    }
  }
`};
`;
