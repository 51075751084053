import {orders_url, checkouts_url} from './urls';
import {post, get, destroy, put, patch} from './fetcher';

export const createOrder = async ({user, email}, callback = () => {}) => {
  const response = await post(orders_url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      order: {
        email,
      },
    }),
  });

  if (!response.ok) {
    return;
  }

  const order = await response.json();

  callback(order);
};

export const addItemOrder = async (
  {user, quantity, variant_id, orderNumber},
  callback = () => {}
) => {
  const response = await post(`${orders_url}/${orderNumber}/line_items`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      line_item: {
        quantity,
        variant_id,
      },
    }),
  });

  if (!response.ok) {
    return;
  }

  const addedItemOrder = await response.json();

  callback(addedItemOrder);
};

export const getCurrentOrder = async (user, callback = () => {}) => {
  const response = await get(`${orders_url}/current`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }

  const currentOrder = response.status === 204 ? {} : await response.json();
  callback(currentOrder);
};

export const deleteItemOrder = async (
  {user, variant_id, orderNumber},
  callback = () => {}
) => {
  const response = await destroy(
    `${checkouts_url}/${orderNumber}/line_items/${variant_id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user}`,
      },
    }
  );

  if (!response.ok) {
    return;
  }

  callback();
};

export const checkoutAddress = async (
  {user, orderNumber, infoAddress},
  callback = () => {}
) => {
  const {
    use_billing,
    bill_address_attributes: {
      id,
      name,
      address1,
      city,
      country_id,
      state_id,
      zipcode,
      phone,
      company,
    },
    ship_address_attributes: {
      id_ship,
      name_ship,
      address1_ship,
      city_ship,
      country_id_ship,
      state_id_ship,
      zipcode_ship,
      phone_ship,
      company_ship,
    },
  } = infoAddress;
  const billingAddressAttributes = id
    ? {id}
    : {
        name,
        address1,
        city,
        country_id,
        state_id,
        zipcode,
        phone,
        company,
      };
  const shippingAddressAttributes = id_ship
    ? {id_ship}
    : {
        name: name_ship,
        address1: address1_ship,
        city: city_ship,
        country_id: country_id_ship,
        state_id: state_id_ship,
        zipcode: zipcode_ship,
        phone: phone_ship,
        company: company_ship,
      };
  const response = await patch(`${orders_url}/${orderNumber}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify(
      use_billing === true
        ? {
            order: {
              use_billing,
              bill_address_attributes: billingAddressAttributes,
            },
          }
        : {
            order: {
              bill_address_attributes: billingAddressAttributes,
              ship_address_attributes: shippingAddressAttributes,
            },
          }
    ),
  });

  if (!response.ok) {
    return;
  }

  const addressResponse = await response.json();

  callback(addressResponse);
};
export const nextStepOrder = async (
  {user, orderNumber},
  callback = () => {}
) => {
  const response = await put(`${checkouts_url}/${orderNumber}/next`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }
  callback();
};
export const checkoutDelivery = async (
  {user, infoDelivery, orderNumber},
  callback = () => {}
) => {
  const {selected_shipping_rate_id, id} = infoDelivery;
  const response = await patch(`${checkouts_url}/${orderNumber}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      order: {
        shipments_attributes: {
          0: {
            selected_shipping_rate_id,
            id,
          },
        },
      },
    }),
  });

  if (!response.ok) {
    return;
  }

  const deliveryResponse = await response.json();

  callback(deliveryResponse);
};
export const checkoutPayment = async (
  {user, infoPayment, orderNumber},
  callback = () => {}
) => {
  const {selected_shipping_rate_id, id} = infoPayment;
  const response = await patch(`${checkouts_url}/${orderNumber}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      order: {
        shipments_attributes: {
          0: {
            selected_shipping_rate_id,
            id,
          },
        },
      },
    }),
  });

  if (!response.ok) {
    return;
  }

  const paymentResponse = await response.json();

  callback(paymentResponse);
};
export const addPayment = async (
  {user, payment, orderNumber},
  callback = () => {}
) => {
  const {
    amount,
    payment_method_id,
    source_attributes: {
      gateway_customer_profile_id,
      gateway_payment_profile_id,
      wallet_payment_source_id,
    },
  } = payment;
  const SelectMetodCreditCard = wallet_payment_source_id
    ? {wallet_payment_source_id}
    : {gateway_customer_profile_id, gateway_payment_profile_id};
  const response = await post(`${orders_url}/${orderNumber}/payments`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      payment: {
        amount,
        payment_method_id,
        source_attributes: SelectMetodCreditCard,
      },
    }),
  });

  if (!response.ok) {
    return;
  }

  const addedPayment = await response.json();

  callback(addedPayment);
};

export const orderComplete = async (
  {user, orderNumber},
  callback = () => {}
) => {
  const response = await put(`${checkouts_url}/${orderNumber}/complete`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }
  callback();
};

export const orderNote = async (
  {user, order_note, orderNumber},
  callback = () => {}
) => {
  const response = await patch(`${checkouts_url}/${orderNumber}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      order: {
        order_note,
      },
    }),
  });

  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }
  callback();
};

export const orderUpdate = async (
  {user, state, orderNumber},
  callback = () => {}
) => {
  const response = await patch(`${orders_url}/${orderNumber}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      order: {
        state: {state},
      },
    }),
  });

  if (!response.ok) {
    return;
  }
  callback();
};

export const getListOrders = async (user, callback = () => {}) => {
  const response = await get(`${orders_url}/mine`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });
  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }
  const listOrders = await response.json();

  callback(listOrders);
};

export const clearOrder = async ({user, orderNumber}, callback = () => {}) => {
  const response = await put(`${orders_url}/${orderNumber}/empty`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    return;
  }
  callback();
};

export const addDiscountCode = async (
  {user, coupon_code, orderNumber},
  callback = () => {}
) => {
  const response = await post(`${orders_url}/${orderNumber}/coupon_codes`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      coupon_code,
    }),
  });
  if (!response.ok) {
    const error = await response.json();
    throw error;
  }
  callback();
};
