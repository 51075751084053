import {Link} from 'gatsby';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {useAlert} from 'react-alert';
import React, {useEffect, useMemo, useState} from 'react';
import {FontWeight, Heading, Span, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import DropDown from '../../core/Dropdown';
import {StyledProductDetails} from './styles';
import ButtonIcon from '../../core/ButtonIcon';
import SkeletonDetails from './SkeletonDetails';
import {QuantityPicker} from 'react-qty-picker';
import MightAlsoLike from '../../MightAlsoLike';
import ModalLogin from '../../Header/modalLogin';
import ButtonBlack from '../../core/ButtonBlack';
import {useGlobalContext} from '../../../context';
import DoubleSlider from '../../core/DoubleSlider';
import CardCollections from '../../core/CardCollections';
import useImgixImage from '../../../hooks/useImgixImage';
import {fetchProduct} from '../../../API/makers/products';
import SliderComponent from '../../Collections/slidershow';
import DropDownItem from '../../core/Dropdown/DropDownItem';
import {getOrder, setOrder} from '../../../services/temporalOrder';
import {createOrder, addItemOrder} from '../../../API/makers/orders';
import {getCurrentUser, isLoggedIn} from '../../../services/authentication';
import {
  getOptionValueByOptionType,
  generateImageUrl,
  filterVariantsByOptionType,
  getSizeLabel,
  getSizeUnits,
} from '../../../utils/productHelper';
import moment from 'moment';

export const DEFAULT_PAIR_SIZE = 12;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: 'auto',
    color: 'red',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '18px',
  },
  overlay: {
    zIndex: '',
  },
};

const ProductsDetailPage = ({productId}) => {
  const SizeRun = useImgixImage('/images/size-run.jpeg');
  const logoEmpty = useImgixImage('/images/logo-empty.png');
  const imgEmptyState = useImgixImage('/images/empty-state.png');
  const cartIcon = useImgixImage('/images/cart-icon.png');
  const banner3 = useImgixImage('/images/banner3.png');
  const heartblack = useImgixImage('/images/heart-black.png');
  const heartgold = useImgixImage('/images/heart-gold.png');
  const handleImage = item => {
    return item.images.length > 0
      ? generateImageUrl(item.images[0].mini_url)
      : imgEmptyState || logoEmpty;
  };
  const alert = useAlert();
  const {
    userLocal,
    itemSelected,
    setItemSelected,
    setDisplayVariant,
    // setModalSizeIsOpen,
    modalIsOpen,
    setModalIsOpen,
    fetchCurrentOrder,
    disableBtn,
    setDisableBtn,
    handleChangeOnFavorite,
    getProduct_id,
  } = useGlobalContext();
  const {product, isLoading} = fetchProduct(productId);
  const tentativeOrderDefault = {
    quantity: 1,
    variant_id: 0,
  };
  const [tentativeOrder, setTentativeOrder] = useState(tentativeOrderDefault);
  const [stateDropdown, setStateDropdown] = useState(false);
  const variantWithSize = useMemo(
    () => itemSelected && filterVariantsByOptionType(itemSelected, 'size'),
    [itemSelected]
  );
  const [disableInput, setDisableInput] = useState(false);
  const handleDisplay = (index, item) => {
    setDisplayVariant(item?.id);
    setItemSelected(item);
    setDisableInput(false);
    setTentativeOrder({...tentativeOrder, variant_id: 0});
  };
  const [colorItems, setColorItems] = useState();
  const variants = product.variants;
  const itemsOptionValues = itemSelected.option_values;
  const selectedColorOption =
    itemsOptionValues &&
    itemsOptionValues.find(
      variant => variant.option_type_name?.toLowerCase() === 'color'
    );
  const colorSelected = selectedColorOption && selectedColorOption.name;
  const filterVariantByColor =
    colorSelected &&
    variants.filter(variant =>
      variant.option_values.find(item => item.name === colorSelected)
    );
  const onChangeValueInput = () => {
    variantWithSize ? setDisableInput(true) : setDisableInput(false);
  };
  const handleTentativeOrderChange = (key, item, defaultValue = undefined) => {
    return event => {
      setTentativeOrder({
        ...tentativeOrder,
        [key]: item ? item : defaultValue || event.target.value,
      });
    };
  };
  const handleCreateOrder = () => {
    setDisableBtn(true);
    if (!isLoggedIn()) {
      alert.error('you need to login');
    } else if (isLoggedIn() && !getOrder()) {
      tentativeOrder.quantity >= 1 && tentativeOrder.variant_id !== 0
        ? createOrder(
            {user: getCurrentUser()['Access-Token'], email: userLocal?.email},
            order => {
              setOrder(order.number);
              addItemOrder(
                {
                  user: getCurrentUser()['Access-Token'],
                  quantity: tentativeOrder.quantity,
                  variant_id: tentativeOrder.variant_id,
                  orderNumber: getOrder(),
                },
                () => {
                  fetchCurrentOrder();
                  handleResetItems();
                  alert.success('You have added this item to your cart');
                }
              );
            }
          )
        : alert.error('incomplete selection');
    } else if (isLoggedIn() && getOrder()) {
      tentativeOrder.quantity >= 1 && tentativeOrder.variant_id !== 0
        ? addItemOrder(
            {
              user: getCurrentUser()['Access-Token'],
              quantity: tentativeOrder.quantity,
              variant_id: tentativeOrder.variant_id,
              orderNumber: getOrder(),
            },
            () => {
              fetchCurrentOrder();
              handleResetItems();
              alert.success('You have added this item to your cart');
            }
          )
        : alert.error('incomplete selection');
    }
  };
  // const openModal = () => {
  //   setModalSizeIsOpen(true);
  // };
  const openModalLogin = () => {
    setModalIsOpen(true);
  };
  const closeModalLogin = () => {
    setModalIsOpen(false);
  };
  // const closeModal = () => {
  //   setModalSizeIsOpen(false);
  // };
  // const afterOpenModal = () => {
  //   if (subtitle) {
  //     subtitle.style.color = '#000';
  //   }
  // };

  // let subtitle;

  const handleResetItems = () => {
    setDisableInput(false);
    setDisplayVariant('');
    handleTentativeOrderChange('quantity', 0);
    handleTentativeOrderChange('variant_id', 0);
    setDisableBtn(true);
    setStateDropdown(false);
    setItemSelected(false);
  };

  useEffect(() => {
    if (tentativeOrder?.quantity !== 0 && tentativeOrder?.variant_id !== 0) {
      setDisableBtn(false);
    }
  }, [tentativeOrder]);
  useEffect(() => {
    if (product.id) {
      let variantsWrap = variants.reduce((reduce, a) => {
        const ov = a.option_values.find(
          ({option_type_name}) => option_type_name?.toLowerCase() === 'color'
        );
        if (!ov) return reduce;
        reduce[ov.name] = [...(reduce[ov.name] || []), a];
        return reduce;
      }, {});
      setColorItems(Object.values(variantsWrap).map(items => items[0]));
    }
  }, [product.id]);
  useEffect(() => {
    itemSelected;
    tentativeOrder;
  }, [itemSelected, tentativeOrder]);

  const isBackorderable = () => {
    if (itemSelected.id) {
      return itemSelected.is_backorderable === true;
    }
    return product.variants.some(item => item.is_backorderable === true);
  };

  const isInStock = () => {
    if (itemSelected.id) {
      return itemSelected.total_on_hand !== 0;
    }
    return product.total_on_hand !== 0;
  };

  useEffect(() => {
    isBackorderable;
    isInStock;
  }, [itemSelected]);

  const ButtonActive = () => {
    return (
      <>
        {isBackorderable() || isInStock() ? (
          <ButtonBlack
            className="wrap-button"
            width="265px"
            height="44px"
            onClick={handleCreateOrder}
            disabled={disableBtn}
          >
            Add to cart <img src={cartIcon} />
          </ButtonBlack>
        ) : (
          <ButtonBlack
            className="wrap-button"
            width="265px"
            height="44px"
            onClick={''}
            disabled={true}
          >
            Out of Stock <img src={cartIcon} />
          </ButtonBlack>
        )}
      </>
    );
  };

  if (isLoading) {
    return <SkeletonDetails />;
  }

  const stockStatus = product => {
    const isBackorderable = product.is_backorderable === true;
    const isInStock = product.total_on_hand >= 1;
    const stock = isInStock
      ? 'IN STOCK'
      : isBackorderable
      ? product.available_on
        ? `AVAILABLE ON: ${moment(product?.available_on)
            .add(1, 'day')
            .startOf('day')
            .format('MMM/DD/YYYY')}`
        : 'PRE-ORDER'
      : 'OUT OF STOCK';
    return stock;
  };

  // Get sample variant and fetch its unit
  // if variant sample is undefined the unit will be 12 pairs
  const sampleVariant =
    tentativeOrder.variant_id > 0
      ? variants.find(({id}) => id === tentativeOrder.variant_id)
      : variants[0];
  const unit = sampleVariant
    ? getSizeUnits(sampleVariant.option_values)
    : DEFAULT_PAIR_SIZE;

  const unitPrice =
    product?.price && unit ? (product?.price / unit).toFixed(2) : 0;

  return (
    <StyledProductDetails>
      <Container>
        <Row className="links-routes">
          <Heading as="h4" level="6">
            Maker’s | SHOE | &nbsp;
          </Heading>
          <Heading as="h5" level="6">
            {product.name}
          </Heading>
        </Row>
        <Row justify={{sm: 'center', md: 'space-between'}}>
          <Col xs="4" sm="4" md="4" lg="6" align={{sm: 'center', md: 'start'}}>
            <DoubleSlider product={product} isLoading={isLoading} />
          </Col>
          <Col sm="8" md="3.3" lg="5.8">
            <div className="add-products-wrapper">
              <Heading as="h3" level="3">
                {product.name ? product.name : 'Product name label'}
              </Heading>
              {isLoggedIn() && (
                <ButtonIcon
                  icon={getProduct_id(product.id) ? heartgold : heartblack}
                  borderColor={
                    getProduct_id(product.id) ? '#CDAD73' : '#d7d9dc'
                  }
                  onClick={() => handleChangeOnFavorite(product.id)}
                ></ButtonIcon>
              )}
            </div>
            <div>
              <Heading
                as="h5"
                level="6"
                color="#9B9FA8"
                fontWeight={FontWeight.Bold}
              >
                REF &nbsp;
                {product.id ? `${product.id}`.padStart(6, '0') : 'REF 001234'}
              </Heading>
            </div>
            {!userLocal ? (
              <div className="text-wrapper-login">
                <Heading level="3" as="h1">
                  Login or register for pricing
                </Heading>
                <Text level="5" as="h1">
                  If you already have an account{' '}
                  <span onClick={openModalLogin}>enter here</span>, or{' '}
                  <Link to={'/register?step=1'}>
                    <span>register now.</span>
                  </Link>
                </Text>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModalLogin}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <ModalLogin />
                </Modal>
              </div>
            ) : (
              <>
                <div
                  className="price-wrapper flex align-center"
                  style={{marginBottom: 0}}
                >
                  <Heading as="h3" level="3" style={{marginRight: 8}}>
                    {product.display_price ? product.display_price : '-'}
                  </Heading>
                  <Span level={5} style={{opacity: 0.6}}>
                    for {unit} pairs
                  </Span>
                </div>
                <div
                  className="flex"
                  style={{
                    opacity: 0.5,
                    fontWeight: FontWeight.SemiBold,
                    marginBottom: 8,
                  }}
                >
                  (${unitPrice} per pair)
                </div>
                {product.trending === true && (
                  <div className="trending-wrapper">
                    <Heading
                      as="h3"
                      level="6"
                      fontWeight={FontWeight.SemiBold}
                      color="#CDAD73"
                    >
                      TRENDING PRODUCT
                    </Heading>
                  </div>
                )}
              </>
            )}
            <div>
              <Text level="5">Model</Text>
              <DropDown
                stateDropdown={stateDropdown}
                onClick={() => setStateDropdown(true)}
                items={colorItems}
                selectItem={({option_values, name}) =>
                  `${name} - ${
                    colorItems &&
                    getOptionValueByOptionType(
                      option_values,
                      'color',
                      'presentation'
                    )
                  }`
                }
                placeholder="Select your model"
                isLoading={isLoading}
                customListProps={{marginBottom: '20px'}}
                itemComponent={(
                  item,
                  index,
                  highlightedIndex,
                  getItemProps
                ) => (
                  <DropDownItem
                    dropDownName="Model"
                    item={item}
                    id={item.id}
                    index={index}
                    miniature={<img src={handleImage(item)} />}
                    colorTitle="black"
                    colorSubtitle="#9B9FA8"
                    title={`${item.name} - ${getOptionValueByOptionType(
                      item.option_values,
                      'color',
                      'presentation'
                    )}`}
                    subtitle={item.slug}
                    priceDisplay={item.display_price}
                    onStock={stockStatus(item)}
                    itemProps={getItemProps({item, index})}
                    highlightedIndex={highlightedIndex}
                    onClick={() => handleDisplay(index, item)}
                  />
                )}
              />
            </div>
            <div>
              <Text
                level={6}
                fontWeight={FontWeight.Light}
                style={{marginBottom: 10, opacity: '70%'}}
              >
                Each box contains {unit} pairs of shoes, all in the same model
                and color. This allows for easy inventory management and ensures
                a consistent style for your wholesale needs. Perfect for
                retailers looking to maintain a cohesive look across their
                offerings. Choose your preferred size from the options below to
                fulfill your inventory requirements efficiently.
              </Text>
              <Text level="5">Sizes run</Text>
              <DropDown
                stateDropdown={stateDropdown}
                disabled={!itemSelected.id}
                onClick={onChangeValueInput}
                dropDownName="Size"
                items={filterVariantByColor || itemSelected}
                placeholder="Select your sizes run"
                selectItem={
                  disableInput
                    ? variant =>
                        `Size ${getOptionValueByOptionType(
                          variant.option_values,
                          'size',
                          'presentation'
                        )}`
                    : false
                }
                itemComponent={(
                  item,
                  index,
                  highlightedIndex,
                  getItemProps
                ) => (
                  <DropDownItem
                    id={item.id}
                    index={index}
                    miniature={
                      <Text as="h1" fontWeight={FontWeight.Bold} level="3">
                        {getSizeLabel(item.option_values)}
                      </Text>
                    }
                    colorTitle="#9B9FA8"
                    colorSubtitle="black"
                    title={getOptionValueByOptionType(
                      item.option_values,
                      'size',
                      'presentation'
                    )}
                    subtitle={'SIZES IN THE BOX'}
                    itemProps={getItemProps({item, index})}
                    highlightedIndex={highlightedIndex}
                    onClick={handleTentativeOrderChange('variant_id', item.id)}
                  />
                )}
                isLoading={isLoading}
              />
            </div>
            {!process.env['GATSBY_ORDER_ACTIVATION'] === true ? (
              ''
            ) : (
              <div>
                <div className="container-row">
                  <div>
                    <Text level="5">Quantity</Text>
                    <QuantityPicker
                      min={1}
                      value={1}
                      onChange={value =>
                        handleTentativeOrderChange('quantity', value)()
                      }
                    />
                  </div>
                  <ButtonActive />
                </div>
                <Text
                  level={6}
                  fontWeight={FontWeight.Light}
                  style={{marginBottom: 10, opacity: '70%'}}
                >
                  * Total pairs: {unit}
                </Text>
              </div>
            )}
          </Col>
        </Row>
        <Row md="8" justify="space-between">
          <Col md="3.8" lg="5.9">
            <div className="head-info">
              <Heading as="h3" level="6">
                PRODUCT DESCRIPTION
              </Heading>
            </div>
            <div>
              <Text level="6">
                {product.description ? (
                  product.description
                ) : (
                  <img src={logoEmpty} />
                )}
              </Text>
            </div>
          </Col>
          <Col md="3.5" lg="5.9">
            <div className="head-info">
              <Heading as="h3" level="6">
                SIZE RUN
              </Heading>
            </div>

            <img src={SizeRun} />
          </Col>
        </Row>
        <Row
          className="you-might-also-like"
          justify={{xs: 'space-between', lg: 'space-around'}}
        >
          <MightAlsoLike />
        </Row>
        <Row className="slider-collection" justify="space-between">
          <Col xs={4} md={8} lg={12}>
            <Heading as="h3" level="4">
              Maker’s collections
            </Heading>
          </Col>
          <Col xs={4} md={4.4} lg={8} justify="start" align="center">
            <SliderComponent />
          </Col>
          <Col xs={4} md={3.4} lg={3.9} justify="start" align="center">
            <CardCollections background={banner3}></CardCollections>
          </Col>
        </Row>
      </Container>
    </StyledProductDetails>
  );
};

export default ProductsDetailPage;

ProductsDetailPage.propTypes = {
  productId: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    variants: PropTypes.array,
    images: PropTypes.shape({
      mini_url: PropTypes.element,
      small_url: PropTypes.element,
      product_url: PropTypes.element,
      larger_url: PropTypes.element,
    }),
  }),
  isLoading: PropTypes.bool,
};
