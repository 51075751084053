import styled from 'styled-components';
import Container, {config} from 'react-awesome-styled-grid';

export const StyledProductDetails = styled.div`
  ${Container} {
    padding: 30px 30px 30px 30px;
    ${props => config(props).media['sm']`
      padding: 30px 60px 90px 60px;
    `}
  }
  .text-wrapper-login {
    margin-top: 20px;
    margin-bottom: 40px;
    span {
      color: ${({theme}) => theme.colors.primary2};
      border-bottom: 1px solid ${({theme}) => theme.colors.primary2};
      :hover {
        color: ${({theme}) => theme.colors.primary3};
        border-bottom: 1px solid ${({theme}) => theme.colors.primary3};
        cursor: pointer;
      }
    }
  }
  .links-routes {
    margin-bottom: 30px;
    h4 {
      padding: 0px;
      margin: 0px;
      color: ${({theme}) => theme.colors.secondary2};
    }
  }
  .add-products-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .price-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    ${props => config(props).media['sm']`
      margin-top: 40px;
      margin-bottom: 10px;
    `}
    h3 {
      margin-right: 20px;
    }
  }
  .trending-wrapper {
    border: 1.5px solid ${({theme}) => theme.colors.primary2};
    border-radius: 28px;
    padding: 6px;
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 30px;
  }
  .head-info {
    cursor: pointer;
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
    padding: 18px 0px 18px;
    margin-bottom: 25px;
    h3 {
      > img {
        margin-left: 10px;
        height: 10px;
        width: 15px;
      }
      :hover {
        color: ${({theme}) => theme.colors.primary2};
      }
    }
  }
  .list-info {
    display: flex;
    flex-flow: column wrap;
    margin: 20px 0px;
    height: 60px;
    li {
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
      line-height: 18px;
    }
  }
  .you-might-also-like {
    margin-top: 20px;
    ${props => config(props).media['sm']`
      margin-top: 60px;
    `}
    h3 {
      margin-bottom: 30px;
    }
  }
  .slider-collection {
    margin-top: 10px;
    ${props => config(props).media['sm']`
      margin-top: 30px;
    `}
    h3 {
      margin-bottom: 30px;
    }
  }
  .container-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${props => config(props).media['sm']`
      flex-direction: row;
    `}
    .quantity-picker {
      display: flex;
      margin-right: 10px;
      border: 1.5px solid ${({theme}) => theme.colors.secondary2};
      margin-top: 8px;
      border-radius: 6px;
      height: 42px;
      width: 99%;
      align-items: center;
      justify-content: space-between;
      ${props => config(props).media['sm']`
        width: 17.8rem;
    `}
    }
    .quantity-modifier {
      font-size: 1rem;
      font-weight: bold;
    }
    .quantity-display {
      background-color: ${({theme}) => theme.colors.secondary3};
      font-size: 16px;
    }
    > button {
      margin-top: 300px !important;
    }
    .wrap-button {
      margin-top: 30px !important;
      margin-bottom: 30px;
      width: 100%;
      ${props => config(props).media['sm']`
        margin-top: 30px;
        margin-bottom: 0px;
        width: 265px;
      `}
    }
    div [disabled] {
      pointer-events: none;
      opacity: 0.7;
      cursor: default;
    }
  }
`;

export const ModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  .modal-head {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    > div {
      cursor: pointer;
    }
    h3 {
      :hover {
        color: ${({theme}) => theme.colors.primary2};
      }
    }
  }
  .info-wrap {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
  }
  .sizes-code-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60px;
    min-width: 60px;
    margin: 0 auto;
    border-right: 1px solid ${({theme}) => theme.colors.primary2};
    h3 {
      text-align: center;
    }
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    .sizes-pairs-wrap {
      display: flex;
      flex-direction: row;
    }
    .sizes-wrap {
      display: flex;
      height: 30px;
      border-right: 1px solid ${({theme}) => theme.colors.primary2};
      align-items: center;
      width: 40px;
      flex-direction: row;
      justify-content: center;
      h4 {
        font-size: ${({theme}) => theme.typography.fontSizes[3]};
      }
    }
    .pairs-wrap {
      display: flex;
      height: 30px;
      border-right: 1px solid ${({theme}) => theme.colors.primary2};
      align-items: center;
      width: 40px;
      flex-direction: row;
      justify-content: center;
      p {
        flex-direction: row;
        text-align: center;
      }
    }
  }
  .total-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin: auto 0;
    width: 80px;
    div {
      align-items: center;
    }
    p {
      text-align: center;
    }
  }
  margin-bottom: 20px;
`;
