import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const StyledCardCategory = styled.div`
  display: flex;
  background-image: url(${({background}) => background});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 200px;
  height: 200px;
  text-align: center;
  transition: background-size 0.8s ease-in-out;
  ${props => config(props).media['sm']`
    width: 250px;
    height: 250px;
  `}
  ${props => config(props).media['xl']`
    width: 350px;
    height: 350px;
  `}
  div {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    p {
      margin-bottom: -50px;
      color: black;
    }
  }
  :hover {
    background-size: 120% 120%;
    p {
      color: ${({theme}) => theme.colors.secondary};
    }
  }
`;
