import styled from 'styled-components';

export const StyledButton = styled.button`
  width: ${({width}) => width};
  height: ${({height}) => height};
  background-color: ${({theme}) => theme.colors.bgColor};
  padding: 0;
  border: none;
  border: 2px solid ${({theme}) => theme.colors.primary2};
  border-radius: 6px;
  outline: none;
  color: white;
  cursor: pointer;
  transition: all 1s ease;

  p {
    color: ${({theme}) => theme.colors.primary2};
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
  }

  &:hover {
    border: 2px solid white !important;
    p {
      color: ${({theme}) => theme.colors.secondary};
      // font-size: 14px;
    }
  }

  &:active {
    outline: none;
    background-color: ${({theme}) => theme.colors.primary2};
    p {
      color: white;
    }
  }
`;
