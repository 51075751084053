import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Heading, Text} from 'styled-typography';
import PropTypes from 'prop-types';
import {ContainerProducts} from './styles';
import {Col, Row} from 'react-awesome-styled-grid';

const SkeletonSearch = () => {
  return (
    <ContainerProducts>
      <Heading level="6" as="h1">
        PRODUCTS
      </Heading>
      <Row>
        <Col align="center" xs={1} md={2}>
          <Text level="6" as="h2">
            <Skeleton className="skeleton-image" />
          </Text>
        </Col>
        <Col align="center" xs={3} md={6}>
          <Text level="6" as="h2">
            <Skeleton className="skeleton-information" />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col align="center" xs={1} md={2}>
          <Text level="6" as="h2">
            <Skeleton className="skeleton-image" />
          </Text>
        </Col>
        <Col align="center" xs={3} md={6}>
          <Text level="6" as="h2">
            <Skeleton className="skeleton-information" />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col align="center" xs={1} md={2}>
          <Text level="6" as="h2">
            <Skeleton className="skeleton-image" />
          </Text>
        </Col>
        <Col align="center" xs={3} md={6}>
          <Text level="6" as="h2">
            <Skeleton className="skeleton-information" />
          </Text>
        </Col>
      </Row>
    </ContainerProducts>
  );
};

export default SkeletonSearch;
SkeletonSearch.propTypes = {
  products: PropTypes.array,
  onResetInput: PropTypes.func,
};
