import useSWR from 'swr';
import {banner_url, lookbook_url, popup_url} from './urls';
import fetcher from './fetcher';

export const fetchBanners = tag => {
  const {data, error} = useSWR(`${banner_url}?q[tag_eq]=${tag}`, fetcher);
  return {
    banners: (data && data.banners) || [],
    error: error,
    isLoading: !data && !error,
  };
};

export const fetchLookbookInfo = () => {
  const {data, error} = useSWR(lookbook_url, fetcher);
  return {
    look_books: (data && data.look_books) || [],
    error: error,
    isLoading: !data && !error,
  };
};

export const fetchPopups = () => {
  const {data, error} = useSWR(`${popup_url}`, fetcher);
  return {
    popups: (data && data.popups) || [],
    error: error,
    isLoading: !data && !error,
  };
};
