import React from 'react';
import {Heading, Text} from 'styled-typography';
import {ContainerProducts} from './styles';
import PropTypes from 'prop-types';
import {Col} from 'react-awesome-styled-grid';
import {Link} from 'gatsby';
import {useGlobalContext} from '../../../context';
import SkeletonSearch from './SkeletonSearch';

const ProductResults = ({title, products}) => {
  const {isLoading: contextLoading, userLocal} = useGlobalContext();

  if (contextLoading) {
    return <SkeletonSearch />;
  }
  return (
    <ContainerProducts>
      <Heading level="6" as="h1">
        {title}
      </Heading>
      {!products.length ? (
        <ContainerProducts>
          <Col align="center">
            <Heading level="6" as="h5">
              EMPTY..
            </Heading>
          </Col>
        </ContainerProducts>
      ) : (
        products.map(product => (
          <div key={product.id} className="container-product">
            <Link to={`/products/${product.id}`} data-id={product.id}>
              <div className="image-container">
                <img src={product.images}></img>
              </div>
            </Link>
            <div className="list-product">
              <Link to={`/products/${product.id}`} data-id={product.id}>
                <Text level="6" as="h2">
                  {product.name}
                </Text>
                {!process.env['GATSBY_ORDER_ACTIVATION'] === true ? (
                  ''
                ) : !userLocal ? (
                  ''
                ) : (
                  <Text level="6" as="h4">
                    {product.price}
                  </Text>
                )}
                <Text level="6" as="h3">
                  {product.inStock}
                </Text>
              </Link>
            </div>
            {/* <div className="line" /> */}
          </div>
        ))
      )}
    </ContainerProducts>
  );
};

export default ProductResults;
ProductResults.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
  images: PropTypes.object,
  onResetInput: PropTypes.func,
  isLoading: PropTypes.bool,
  isValidating: PropTypes.bool,
};
