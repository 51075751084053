import styled from 'styled-components';

export const StyledFormInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 23px;
  margin-bottom: 20px;

  & input {
    height: 44px;
    width: calc(100% - 42px);
    border-radius: 6px;
    outline: none;
    padding: 0 20px;
    font-size: 0.8em;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.secondary2};
    border-width: 1.5px;
    margin-top: 8px;
    margin-bottom: 10px;
    :focus {
      outline: none;
      border: solid 1px ${({theme}) => theme.colors.primary2};
    }
  }

  Label {
    margin-left: 0px;
    font-size: 13px;
  }

  .error-wrapper {
    > span {
      margin: 0px;
      padding: 0px;
      bottom: 0px;
      left: 0px;
      width: 80%;
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
    }
  }
`;
