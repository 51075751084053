import React from 'react';
import {Col, Hidden, Row, Visible} from 'react-awesome-styled-grid';
import {FontWeight, Heading, Text} from 'styled-typography';
import {Link} from 'gatsby';
import Modal from 'react-modal';

import {StyledFooter} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import {useGlobalContext} from '../../context';
import ModalSize from '../pages/ProductDetails/modalSize';

const Footer = () => {
  const Logo = useImgixImage('/images/Menu-Desktop-Makers-logo.svg');
  const divLine = useImgixImage('/images/divline.png');
  const instagramIcon = useImgixImage('/images/instagram-icon.svg');
  const youtubeIcon = useImgixImage('/images/youtube-icon.svg');
  const facebookIcon = useImgixImage('/images/facebook-icon.svg');
  const makersMonogram = useImgixImage('/images/makers-monogram.svg');
  const {modalSizeIsOpen, setModalSizeIsOpen} = useGlobalContext();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: 'auto',
      color: 'red',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '18px',
    },
    overlay: {
      zIndex: '',
    },
  };
  const openModalSize = () => {
    setModalSizeIsOpen(true);
  };
  const closeModal = () => {
    setModalSizeIsOpen(false);
  };
  return (
    <StyledFooter>
      <Row align="center" justify="space-between" className="head">
        <Col xs="2" sm="2" lg="10">
          <Link to={'/'}>
            <img src={Logo} className="logo" />
          </Link>
        </Col>
        <Col xs="0.5" sm="1" lg="2" align="end">
          <img src={makersMonogram} className="makers-monogram" />
        </Col>
      </Row>
      <Row className="container-info" justify={{xs: 'start', sm: 'center'}}>
        <Col xs="2" sm="1" lg="1" xl="1.3">
          <Heading as="h3" fontWeight={FontWeight.Bold}>
            ABOUT US
          </Heading>
          <Link to={'/we-are-makers'}>
            <li>Our Story</li>
          </Link>
          <Link to={'/search-page?page=1&per_page=16&search=meraki'}>
            <li>Meraki</li>
          </Link>
          <Link to={'/search-page?page=1&per_page=16&search=sunria'}>
            <li>Sunria</li>
          </Link>
        </Col>
        <Col xs="2" sm="1" lg="1" xl="1.5">
          <Heading as="h3" fontWeight={FontWeight.Bold}>
            PRODUCTS
          </Heading>
          <ul>
            <Link to={'/all-shoes'}>
              <li>Categories</li>
            </Link>

            <li>Trending</li>
            <Link to={'/lookbook'}>
              <li>Lookbook</li>
            </Link>
            <Link to={'/weekly-arrivals'}>
              <li>Weekly Arrivals</li>
            </Link>
          </ul>
        </Col>
        <Col xs="2" sm="1" lg="1" xl="1">
          <Heading as="h3" fontWeight={FontWeight.Bold}>
            SUPPORT
          </Heading>
          <ul>
            <Link to={'/lets-be-partners'}>
              <li>Contact Us</li>
            </Link>
            <li>
              <div onClick={openModalSize}>Size run guide</div>
              <Modal
                isOpen={modalSizeIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <ModalSize />
              </Modal>
            </li>
            <li>
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
            </li>
          </ul>
        </Col>
        <Col sm="0.4" align="center" className="div-line">
          <img src={divLine} />
        </Col>
        <Hidden xs>
          <Col xs="4" sm="2.5" lg="2.5" xl="3" className="contact">
            <Heading as="h3" level="6" fontWeight={FontWeight.Bold}>
              CONTACT
            </Heading>
            <Text level="6" fontWeight={FontWeight.Normal}>
              Do you have any questions or suggestions?
            </Text>
            <Heading as="h4" fontWeight={FontWeight.Normal}>
              contact@makersshoes.com
            </Heading>
            <Text level="6" fontWeight={FontWeight.Normal}>
              Do you need support? Give us a call.
            </Text>
            <Heading as="h4" fontWeight={FontWeight.Normal}>
              +1 (305) 576-1195
            </Heading>
          </Col>
        </Hidden>
        <Visible xs>
          <Col xs="2" className="follow-us">
            <Heading as="h3" fontWeight={FontWeight.Bold}>
              FOLLOW US
            </Heading>
            <Link
              to={'https://www.instagram.com/makersshoes_official/'}
              target="_blank"
            >
              <div>
                <img src={instagramIcon} />
                <Text level="6" justify="center">
                  Makersshoes_official
                </Text>
              </div>
            </Link>
            <Link
              to={'https://www.facebook.com/makersshoesofficial'}
              target="_blank"
            >
              <div>
                <img src={facebookIcon} />
                <Text level="6" justify="center">
                  MakersshoesOfficial
                </Text>
              </div>
            </Link>
            <Link
              to={'https://www.youtube.com/channel/UCZVSsjb_374Isu65So8Wlig'}
              target="_blank"
            >
              <div>
                <img src={youtubeIcon} />
                <Text level="6" justify="center">
                  Maker&apos;s Shoes Official
                </Text>
              </div>
            </Link>
          </Col>
        </Visible>
        <Hidden xs>
          <Col xs="4" sm="1" lg="2" xl="1.5" className="follow-us">
            <Heading as="h3" fontWeight={FontWeight.Bold}>
              FOLLOW US
            </Heading>
            <Link
              to={'https://www.instagram.com/makersshoes_official/'}
              target="_blank"
            >
              <div>
                <img src={instagramIcon} />
                <Text level="6" justify="center">
                  Makersshoes_official
                </Text>
              </div>
            </Link>
            <Link
              to={'https://www.facebook.com/makersshoesofficial'}
              target="_blank"
            >
              <div>
                <img src={facebookIcon} />
                <Text level="6" justify="center">
                  MakersshoesOfficial
                </Text>
              </div>
            </Link>
            <Link
              to={'https://www.youtube.com/channel/UCZVSsjb_374Isu65So8Wlig'}
              target="_blank"
            >
              <div>
                <img src={youtubeIcon} />
                <Text level="6" justify="center">
                  Maker&apos;s Shoes Official
                </Text>
              </div>
            </Link>
          </Col>
        </Hidden>
        <Visible xs>
          <Col xs="4" className="contact">
            <Heading as="h3" level="6" fontWeight={FontWeight.Bold}>
              CONTACT
            </Heading>
            <Text level="6" fontWeight={FontWeight.Normal}>
              Do you have any questions or suggestions?
            </Text>
            <Heading as="h4" fontWeight={FontWeight.Normal}>
              contact@makersshoes.com
            </Heading>
            <Text level="6" fontWeight={FontWeight.Normal}>
              Do you need support? Give us a call.
            </Text>
            <Heading as="h4" fontWeight={FontWeight.Normal}>
              +1 (305) 576-1195
            </Heading>
          </Col>
        </Visible>
      </Row>
      <Row>
        <Col className="copyright">
          <Text level="6" justify="center">
            ©2021 Marker’s | All Rights Reserved.
          </Text>
        </Col>
      </Row>
    </StyledFooter>
  );
};

export default Footer;
