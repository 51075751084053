import {config, Row} from 'react-awesome-styled-grid';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownHeaderItem,
  DropDownHeaderItemIcon,
  DropDownList,
  DropDownListItem,
} from '../core/Dropdown/styles';

export const LogoWrap = styled.div`
  width: 100px;
  flex: 0 1 36px;
  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
  > div {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  img :first-child {
    max-height: 50px;
    margin-right: 10px;
  }
  img :last-child {
    max-height: 40px;
    margin-bottom: -10px;
  }
`;

export const Navigation = styled.header`
  padding: 0px;
  position: fixed;
  width: 100%;
  z-index: 4;
  text-transform: uppercase;
  background-color: ${({theme}) => theme.colors.bgColor};
  ${props => config(props).media['md']`
      position: relative;
      z-index:1;
   `}
  .login-wrapper {
    display: flex;
    margin-right: 10px;
    h6 {
      text-align: right;
      text-transform: lowercase;
      color: ${({theme}) => theme.colors.primary2};
    }
    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }
  .search {
    right: 29%;
    z-index: 1;
    bottom: 18%;
    cursor: pointer;
    position: absolute;
    ${props => config(props).media['md']`
      right: 38%;
      bottom: 62%;
   `}
    ${props => config(props).media['lg']`
      right: 40%;
      bottom: 62%;
   `}
  }
  ${Row} {
    margin: 0px;
  }
  ${Row}:first-child {
    margin: 0px;
    width: 100%;
    height: 140px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff0f1;
    ${props => config(props).media['md']`
      height: 99px;
   `}

    .style-button {
      display: flex;
      margin-right: 26px;
      align-items: center;
      flex-direction: row;
      .login-account-wrap {
        width: 100%;
        z-index: 2;
        justify-content: flex-end;
        margin-right: 26px;
        height: 15px;
      }
      ${DropDownContainer} {
        z-index: 1;
        width: 150px;
        margin-bottom: 0px;
        ul {
          li:first-child > div {
            width: 120px;
            border: none;
            padding-top: 12px;
            padding-bottom: 5px;
          }
          li:last-child > div {
            width: 120px;
            border: none;
            padding-top: 5px;
            padding-bottom: 12px;
          }
        }
      }
      ${DropDownHeader} {
        width: 100%;
        border: none;
        padding: 0px;
        display: flex;
        margin-top: 0px;
        cursor: pointer;
        border-radius: 0px;
        margin-bottom: 8px;
        align-items: center;
        background: transparent;
        justify-content: flex-end;
        img {
          margin-left: 10px;
        }
      }
      ${(DropDownHeaderItemIcon, DropDownHeaderItem, DropDownList)} {
        max-height: 345px;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
        margin-top: 0px;
        border-radius: 10px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
      }
      ${DropDownListItem} {
        padding: 0px;
        border-radius: 0px;
        padding-left: 15px;
        padding-right: 15px;
        .dropdownlistwrapper {
          height: 30px;
          border: none;
          > div {
            margin: 0px;
            width: 20px;
            height: 20px;
            background: transparent;
            border: none;
            img {
              width: 20px;
              height: 20px;
            }
          }
          div :last-child {
            width: 88px;
          }
        }
        p {
          text-transform: none;
          font-size: ${({theme}) => theme.typography.fontSizes[4]};
          line-height: ${({theme}) => theme.typography.fontSizes[4]};
          font-weight: 500;
        }
      }

      > div {
        margin-left: 12px;
      }
      .link-button {
        margin-right: 15px;
      }
      .link-button-items {
        margin-right: 15px;
      }
      .link-button-number-items {
        > button {
          background-color: black;
          width: 30px;
          height: 30px;
          position: absolute;
          border: 1.5px solid ${({theme}) => theme.colors.secondary2};
          margin-top: -50px;
          margin-left: 30px;
        }
      }
      .button-user {
        width: 40px;
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        height: 40px;
        margin-right: 16px;
        margin-top: 2px;
        border-radius: 26px;
        border: 1.5px solid ${({theme}) => theme.colors.secondary2};
        cursor: pointer;

        &:hover {
          border: 1.5px solid black;
        }

        &:active {
          border: 1.5px solid ${({theme}) => theme.colors.primary3};
        }
      }
    }
  }

  ${props => config(props).media['md']`
 
  `}

  ${LogoWrap} img {
    margin-top: 10px;
    height: 30px;
    margin-left: 14px;
    ${props => config(props).media['md']`
        margin-top: 0px;
        height: unset;
      `}
  }
`;

export const StyledInput = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  input {
    display: flex;
    height: 40px;
    width: 300px;
    display: flex;
    border: none;
    text-indent: 12px;
    font-family: 'inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: black;
    ${props => config(props).media['md']`
      height: 50px;
      width: 300px;
      display: flex;
      text-indent: 12px;
      outline: 10px;
      border:none;
      font-family: 'inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: black;
    `}
  }
  .lupa {
    height: 16px;
    width: 16px;
  }
`;

export const Toggle = styled.div`
  position: absolute;
  top: -15px;
  right: 15px;
  height: 75%;
  cursor: pointer;
  width: 10%;
  justify-content: flex-end;
  display: flex;
  ${props => config(props).media['md']`
    display: none;
  `}
`;

export const Navbox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position: fixed;
  width: 800%;
  justify-content: flex-start;
  padding-top: 80vh;
  background-color: ${({theme}) => theme.colors.bgColor};

  transition: all 0.3s ease-in, background-color 0s;
  top: 10vh;
  left: ${props => (props.open ? '-100%' : '0')};

  ${props => config(props).media['md']`
    display: flex;
    background-color: white;
    background-size: contain;
    margin-right: -90px;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    flex-direction: unset;
    padding-top:1px;
    transition: unset;
  `}
`;

export const Hamburger = styled.div`
  z-index: 2;
  background-color: black;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  top: 0;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    z-index: 120;

    width: 30px;
    height: 3px;
    background-color: black;
    content: '';
    position: absolute;
  }

  ::before {
    z-index: 120;

    transform: ${props =>
      props.open ? 'rotate(90deg) translate(10px, 0px)' : 'rotate(0deg)'};
    top: -10px;
  }

  ::after {
    z-index: 120;

    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

export const NavItem = styled(Link)`
  display: flex;
  margin: 10px 12px;
  transition: all 200ms ease-in;
  justify-content: center;

  @media (max-width: 400px) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0px 12px;
  }
`;

export const ModalStyle = styled.div`
  z-index: 1;
  display: flex;
  width: 100%;
  margin: 0px 0px;
  flex-direction: column;
  .invalid {
    color: ${({theme}) => theme.colors.primary2};
    cursor: default;
    margin-bottom: 10px;
    :hover {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
  .input-select {
    display: flex;
    height: 44px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    outline: none;
    padding: 0 20px;
    font-size: 0.8em;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.secondary2};
    border-width: 1.5px;
    margin-top: 10px;
    margin-bottom: 28px;
    :focus {
      outline: none;
      border: solid 1px ${({theme}) => theme.colors.primary2};
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
      :hover {
        filter: contrast(0.5);
      }
    }
  }
  h2 {
    color: black;
  }
  h3 {
    color: ${({theme}) => theme.colors.secondary};
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h5 {
    color: ${({theme}) => theme.colors.secondary};
    text-align: center;
  }
  span {
    justify-content: center;
    color: ${({theme}) => theme.colors.primary2};
    cursor: pointer;
    :hover {
      color: ${({theme}) => theme.colors.primary3};
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    margin-bottom: 20px;
    margin-right: 5px;
  }
  label {
    color: ${({theme}) => theme.colors.secondary};
  }
  button {
    margin-bottom: 20px;
  }
`;
