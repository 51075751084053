import {useState, useCallback} from 'react';
import ImgixClient from '@imgix/js-core';

const useImgixImage = (uri, defaultParams) => {
  const [params, setParams] = useState(defaultParams);
  useCallback(() => {
    setParams(defaultParams);
  }, [params]);

  const client = new ImgixClient({
    domain: process.env.GATSBY_IMGIX_URL,
    secureURLToken: process.env.GATSBY_IMGIX_TOKEN,
  });

  return client.buildURL(uri, params);
};

useImgixImage.defaultProps = {
  defaultParams: {},
};

export default useImgixImage;
