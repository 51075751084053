import React from 'react';
import Skeleton from 'react-loading-skeleton';

import StyledSlider from './styles';

const SkeletonSlider = tag => {
  return (
    <StyledSlider tag={tag.tag}>
      <Skeleton className="skeletonStyle" circle={true} count={1} />
    </StyledSlider>
  );
};

export default SkeletonSlider;
