import Modal from 'react-modal';
import {Link, navigate} from 'gatsby';
import '@szhsin/react-menu/dist/index.css';
import {Heading, Text} from 'styled-typography';
import {Hidden} from 'react-awesome-styled-grid';
import '@szhsin/react-menu/dist/transitions/slide.css';
import React, {useState, useEffect, useRef} from 'react';
import {ControlledMenu, MenuItem} from '@szhsin/react-menu';

import ModalLogin from '../Header/modalLogin';
import {useGlobalContext} from '../../context';
import useImgixImage from '../../hooks/useImgixImage';
import {isLoggedIn} from '../../services/authentication';
import {generateImageUrl} from '../../utils/productHelper';
import {MenuStyle, ContainerLoginStyle, MenuItems} from './styles';
import {fetchTaxons, fetchTaxonsCategory} from '../../API/makers/taxons';

const MenuPage = ({isOpen, setNavbarOpen}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: 'auto',
      color: 'red',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '18px',
    },
    overlay: {
      zIndex: '',
    },
  };
  const menu = useImgixImage('/images/menu-category.png');
  const {taxons, isLoading} = fetchTaxons();
  const {taxonsCategory} = fetchTaxonsCategory();
  const logoEmpty = useImgixImage('/images/logo-empty.png');
  const {setFilterStyle, modalIsOpen, setModalIsOpen, userLocal, currentOrder} =
    useGlobalContext();
  const {total_quantity} = currentOrder || '';
  const timeoutId = useRef(null);
  const ref = useRef(null);
  const refBrand = useRef(null);
  const [state, setState] = useState(false);
  const [OptionOpenBrand, setOptionOpenBrand] = useState(false);

  const handleClick = key => {
    setFilterStyle(key);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setNavbarOpen(!isOpen);
  };
  const sendToFavorite = value => {
    navigate(value);
  };
  const handleBrandOpen = () => {
    setState(false);
    setOptionOpenBrand(true);
  };
  const handleCategoriesOpen = () => {
    setOptionOpenBrand(false);
    setState(true);
  };
  const handleBrandMouseLeave = () => {
    timeoutId.current = setTimeout(() => {
      setOptionOpenBrand(false);
    }, 300);
  };
  const handleCategoriesMouseLeave = () => {
    timeoutId.current = setTimeout(() => {
      setState(false);
    }, 300);
  };

  useEffect(() => {
    total_quantity;
  }, [currentOrder]);

  function removeCatchLinkEventFor(key) {
    document.addEventListener(
      'click',
      function (element) {
        if (element.target.classList.contains(key)) {
          element.stopPropagation();
        }
      },
      true
    );
  }

  useEffect(() => {
    removeCatchLinkEventFor('remove-catch-link-event');
  }, []);

  return (
    <MenuStyle isOpen={isOpen}>
      <Heading as="h3" level="6">
        <div
          ref={refBrand}
          onMouseEnter={() => {
            clearTimeout(timeoutId.current);
            handleBrandOpen();
          }}
          onMouseLeave={handleBrandMouseLeave}
        >
          <Hidden xs sm>
            <Link>BRANDS</Link>
          </Hidden>

          <Hidden md lg xl>
            <div
              className="wrapper-mobile menu"
              onClick={() => setOptionOpenBrand(!OptionOpenBrand)}
            >
              <Link>BRANDS </Link>
            </div>
            <MenuItems open={OptionOpenBrand}>
              <ul>
                <li>
                  <Link
                    to={'/search-page?page=1&per_page=16&search=meraki'}
                    onClick={closeModal}
                  >
                    Meraki
                  </Link>
                </li>
                <li>
                  <Link
                    to={'/search-page?page=1&per_page=16&search=sunria'}
                    onClick={closeModal}
                  >
                    Sunria
                  </Link>
                </li>
              </ul>
            </MenuItems>
          </Hidden>
        </div>
        <ControlledMenu
          state={OptionOpenBrand}
          anchorRef={refBrand}
          onMouseEnter={() => clearTimeout(timeoutId.current)}
          onMouseLeave={handleBrandMouseLeave}
          isOpen={OptionOpenBrand}
          className="ContainerBrandMenu"
        >
          <div className="ContainerMenu ">
            {!taxons.length && !isLoading ? (
              <></>
            ) : (
              <Text level="4" as="h2">
                <MenuItem>
                  <Link
                    className="link-menu remove-catch-link-event"
                    to={'/search-page?page=1&per_page=16&search=sunria'}
                    onClick={closeModal}
                  >
                    Sunria
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    className="link-menu remove-catch-link-event"
                    to={'/search-page?page=1&per_page=16&search=meraki'}
                    onClick={closeModal}
                  >
                    Meraki
                  </Link>
                </MenuItem>
              </Text>
            )}
          </div>
        </ControlledMenu>
      </Heading>
      <Heading
        as="h3"
        level="6"
        onMouseEnter={() => {
          clearTimeout(timeoutId.current);
          handleCategoriesOpen();
        }}
        onMouseLeave={handleCategoriesMouseLeave}
      >
        <div ref={ref}>
          <Hidden xs sm>
            <Link>CATEGORIES</Link>
          </Hidden>

          <Hidden md lg xl>
            <div
              className="wrapper-mobile menu"
              onClick={() => setState(!state)}
            >
              <Link>CATEGORIES </Link>
            </div>
            <MenuItems open={state}>
              <ul>
                <li>
                  <Link to={'/all-shoes'} onClick={closeModal}>
                    All Shoes
                  </Link>
                </li>
                <li>
                  <Link to={'/all-shoes?style=8,sandals'} onClick={closeModal}>
                    Sandals
                  </Link>
                </li>
                <li>
                  <Link to={'/all-shoes?style=4,sneakers'} onClick={closeModal}>
                    Sneakers
                  </Link>
                </li>
                <li>
                  <Link to={'/all-shoes?style=9,flats'} onClick={closeModal}>
                    Flats
                  </Link>
                </li>
                <li>
                  <Link to={'/all-shoes?style=6,heels'} onClick={closeModal}>
                    Heels
                  </Link>
                </li>
                <li>
                  <Link to={'/all-shoes?style=5,boots'} onClick={closeModal}>
                    Boots
                  </Link>
                </li>
                <li>
                  <Link to={'/all-shoes?style=7,wedges'} onClick={closeModal}>
                    Wedges
                  </Link>
                </li>
              </ul>
            </MenuItems>
          </Hidden>
        </div>
        <ControlledMenu
          state={state}
          anchorRef={ref}
          onMouseEnter={() => clearTimeout(timeoutId.current)}
          onMouseLeave={handleCategoriesMouseLeave}
          onClick={() => setState(!state)}
        >
          <div className="ContainerMenu">
            {!taxons.length && !isLoading ? (
              <></>
            ) : (
              <Text level="4" as="h2">
                <MenuItem onClick={() => handleClick('ALL')}>
                  <Link
                    className="link-menu remove-catch-link-event"
                    to={'/all-shoes'}
                  >
                    All Shoes
                  </Link>
                </MenuItem>
              </Text>
            )}
            {!taxons.length && !isLoading ? (
              <img src={logoEmpty} className="empty-state"></img>
            ) : (
              <Text level="4" as="h2">
                {taxons.map(taxon => (
                  <MenuItem key={taxon.id}>
                    <Link
                      className="LinkMenu remove-catch-link-event"
                      to={`/all-shoes?style=${taxon.id},${taxon.name}`}
                    >
                      {taxon.name}
                    </Link>
                  </MenuItem>
                ))}
              </Text>
            )}
          </div>
          <div className="ContainerImage">
            <img
              src={
                taxonsCategory
                  ? generateImageUrl(taxonsCategory[0]?.image_url)
                  : menu
              }
              className="image"
            ></img>
          </div>
        </ControlledMenu>
      </Heading>
      <Heading as="h3" level="6">
        <Hidden xs sm>
          <Link to={'/lookbook'} onClick={closeModal}>
            LOOKBOOK
          </Link>
        </Hidden>

        <Hidden md lg xl>
          <Link
            className="wrapper-mobile"
            to={'/lookbook'}
            onClick={closeModal}
          >
            LOOKBOOK
          </Link>
        </Hidden>
      </Heading>
      <Heading as="h3" level="6">
        <Hidden xs sm>
          <Link to={'/weekly-arrivals'} onClick={closeModal}>
            WEEKLY ARRIVALS
          </Link>
        </Hidden>
        <Hidden md lg xl>
          {' '}
          <Link
            className="wrapper-mobile"
            to={'/weekly-arrivals'}
            onClick={closeModal}
          >
            WEEKLY ARRIVALS
          </Link>
        </Hidden>
      </Heading>
      <Hidden md lg xl>
        <ContainerLoginStyle>
          {userLocal ? (
            <div
              className="cart-favorite"
              onClick={() => sendToFavorite('/account?setting=personal-info')}
            >
              <Link level="5" as="h3">
                {`Hello, ${userLocal.first_name}`}
              </Link>
            </div>
          ) : (
            <>
              <div onClick={openModal}>
                <Text level="5" as="h3">
                  Sign / Register
                </Text>
              </div>
              <Modal
                isOpen={modalIsOpen}
                onClick={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <ModalLogin />
              </Modal>
            </>
          )}
          <div
            className="cart-favorite"
            onClick={() => sendToFavorite('/favorite-page')}
          >
            <Link level="5" as="h3">
              Favorites
            </Link>
          </div>
          {isLoggedIn() && currentOrder && total_quantity >= 1 && (
            <div
              className="cart-favorite"
              onClick={() => sendToFavorite('/cart')}
            >
              <Link level="5" as="h3">
                Cart (
                {`${
                  total_quantity >= 1 && total_quantity <= 99
                    ? total_quantity
                    : '99+'
                }`}
                )
              </Link>
            </div>
          )}
        </ContainerLoginStyle>
      </Hidden>
    </MenuStyle>
  );
};

MenuPage.propTypes = {
  isOpen: false,
  setNavbarOpen: false,
};

export default MenuPage;
