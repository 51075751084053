import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';
import {StyledButton} from '../Button/styles';

export const StyledCard = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 90%;
  max-width: 160px;
  ${props => config(props).media['sm']`
    max-width: 250px;
  `}
  a {
    width: 95%;
  }

  ${StyledButton} {
    position: absolute;
    margin-top: 10px;
    margin-left: 153px;
    background-color: black;
    width: 134px;
    height: 33px;
    ${props => config(props).media['sm']`
       margin-left: 153px;
       `}
    ${props => config(props).media['md']`
       margin-left: 163px;
     `}
     ${props => config(props).media['lg']`
       margin-left: 165px;
     `}
     p {
      color: white;
      font-size: 11px;
      letter-spacing: 0.1em;
    }
    &:hover {
      background-color: #434447;
      p {
        color: white;
      }
    }
    &:active {
      outline: none;
      background-color: ${({theme}) => theme.colors.primary};
      p {
        color: white;
      }
    }
  }

  .wrap-card {
    max-width: 160px;
    ${props => config(props).media['sm']`
    max-width: 250px;

  `}
    .wrap-card-image {
      width: 100%;
      height: 100%;
      padding-top: calc(355 / 250 * 100%);
      position: relative;
      display: flex;
      justify-content: center;
      .image-main {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url(${({imageMain}) => [imageMain]});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .wrap-card-information {
    padding: 5px 0;
    .wrap-card-information-name-product {
      height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      ${props => config(props).media['md']`
          height: 20px;
        `}
    }
    h5 {
      color: ${({theme}) => theme.colors.secondary};
      font-size: 12px;
      font-weight: Normal;
      margin: 3px 0;
      :first-child {
        font-weight: Bold;
        font-size: 12px;
      }
      :last-child {
        font-weight: Bold;
        font-size: 10px;
      }
      ${props => config(props).media['md']`
          font-size: 14px;
          margin: 5px 0;
          :first-child {
            font-size: 14px;
          }
          :last-child {
            font-size: 12px;
          }
        `}
    }
    ${props => config(props).media['md']`
      padding: 10px 0;
    `}
  }
  .container-colorpicker {
    position: absolute;
    bottom: 10px;
  }
`;
