import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const MightAlsoLikeStyle = styled.div`
  width: 100%;
  .gAKlQt .image-wrapper {
    width: 150px;
    height: 170px;
    ${props => config(props).media['md']`
      width: 280px !important;
      height: 340px !important;
    `}
  }
  h3 {
    display: flex;
    justify-content: space-between;
    margin-top: 41px;
    margin-left: 33px;
    margin-bottom: 40px;
    font-size: 24px;
  }
`;
