import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const StyledCardCollections = styled.div`
  width: 100%;
  margin-top: 30px;
  ${props => config(props).media['md']`
      margin-top: 0px;
      `}
  div {
    flex-direction: column;
    background: url(${({background}) => background}) no-repeat;
    background-size: cover;
    border-radius: 20px;
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
      width: 250px;
      font-size: 30px;
      font-weight: 600;
      color: white;
      margin-bottom: 25px;
      margin-right: 50px;
    }
  }
  .container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-around;
    width: 100%;
    height: 70px;
    flex-direction: revert;
    background: transparent;
    align-content: row;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95px;
      height: 40px;
      margin-right: 5px;
      border-radius: 28px;
      background: rgba(255, 255, 255, 0.09);
      border: 1.5px solid rgba(255, 255, 255, 0.9);
      box-sizing: border-box;
      cursor: pointer;
      backdrop-filter: blur(17px);
      &:hover {
        background-color: white;

        p {
          color: ${({theme}) => theme.colors.secondary};
        }
      }
    }
    p {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      color: white;
      letter-spacing: 0.1em;
    }
  }
  .line {
    border-radius: 0;
    background: none;
    width: 300px;
    height: auto;
    border-bottom: 1px solid white;
  }
`;
