import useSWR from 'swr';

import fetcher from './fetcher';
import {post, patch, put, get} from './fetcher';
import {setUser} from '../../services/authentication';
import {
  login_url,
  account_url,
  email_url,
  password_url,
  magic_link_url,
} from './urls';

export const signIn = async ({email, password}, callback = () => {}) => {
  const response = await post(login_url, {
    body: JSON.stringify({
      email,
      password,
    }),
  });
  if (!response.ok) {
    throw false;
  }

  const user = await response.json();
  setUser({
    'Access-Token': response.headers.get('Access-Token'),
    id: user.id,
    email: user.email,
    first_name: user.first_name,
  });
  callback(user);
};

export const autoLogin = async (token, callback = () => {}) => {
  const response = await post(magic_link_url, {
    headers: {
      token: token,
    },
  });
  if (!response.ok) {
    throw false;
  }

  const user = await response.json();

  setUser({
    'Access-Token': response.headers.get('Access-Token'),
    id: user.id,
    email: user.email,
    first_name: user.first_name,
  });

  callback(user);
};

export const fetchAccount = id => {
  const {data, error} = useSWR(`${account_url}/${id}`, fetcher);
  return {
    accountUser: (data && data) || {company: {}},
    isLoading: !data && !error,
    error: error,
  };
};

const updateUser = async (idUser, token, account, callback = () => {}) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    passwordConfirmation,
    company: {
      id,
      companyName,
      cellNumber,
      companyPhoneNumber,
      companyEmail,
      address,
      country,
      state,
      city,
      postCode,
      taxOrIdNumber,
      webSiteURL,
    },
  } = account;

  const response = await patch(`${account_url}${idUser}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(
      password === '' && passwordConfirmation === ''
        ? {
            user: {
              first_name: firstName,
              last_name: lastName,
              phone_number: phoneNumber,
              email: email,
              company_attributes: {
                id: id,
                company_name: companyName,
                phone_number: companyPhoneNumber,
                cell_number: cellNumber,
                email: companyEmail,
                address,
                city,
                zip_code: postCode,
                tax_id_number: taxOrIdNumber,
                website_url: webSiteURL,
                spree_country_id: country,
                spree_state_id: state,
              },
            },
          }
        : {
            user: {
              first_name: firstName,
              last_name: lastName,
              phone_number: phoneNumber,
              email: email,
              password,
              password_confirmation: passwordConfirmation,
              company_attributes: {
                id: id,
                company_name: companyName,
                phone_number: companyPhoneNumber,
                cell_number: cellNumber,
                email: companyEmail,
                address,
                city,
                zip_code: postCode,
                tax_id_number: taxOrIdNumber,
                website_url: webSiteURL,
                spree_country_id: country,
                spree_state_id: state,
              },
            },
          }
    ),
  });
  if (!response.ok) {
    return;
  } else if (response.ok) {
    const user = await response.json();
    setUser({
      'Access-Token': response.headers.get('Access-Token'),
      id: user.id,
      email: user.email,
      first_name: user.first_name,
    });
    callback();
  }
};
export default updateUser;

export const SendEmailPassword = async (emailDefault, callback = () => {}) => {
  const {email} = emailDefault;

  const response = await post(email_url, {
    body: JSON.stringify({
      email: email,
    }),
  });

  if (!response.ok) {
    return;
  } else if (response.ok) {
    callback();
  }
};

export const resetPassword = async (passwordPut, callback = () => {}) => {
  const {password, passwordConfirmation, tokenPassword} = passwordPut;

  const response = await put(`${password_url}`, {
    body: JSON.stringify({
      password: password,
      password_confirmation: passwordConfirmation,
      reset_password_token: tokenPassword,
    }),
  });
  if (!response.ok) {
    return;
  } else if (response.ok) {
    callback();
  }
};

export const getAddress = async ({user, idUser}, callback = () => {}) => {
  const response = await get(`${account_url}/${idUser}/address_book`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }

  const getAddress = await response.json();

  callback(getAddress);
};

export const getCreditCards = async ({user, idUser}, callback = () => {}) => {
  const response = await get(`${account_url}/${idUser}/credit_cards`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    throw 'An error occurred while fetching the data.';
  }

  const getCreditCard = await response.json();

  callback(getCreditCard);
};
