import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import {Hidden} from 'react-awesome-styled-grid';
import {Link} from 'gatsby';
import moment from 'moment';

import useImgixImage from '../../../hooks/useImgixImage';
import {generateImageUrl} from '../../../utils/productHelper';
import {StyledCard} from './styles';
import Button from '../Button';
import ColorPicker from '../ColorPicker';
import {useGlobalContext} from '../../../context';

const Card = ({img, buttonText, productName, product, productId, Price}) => {
  const emptyProduct = useImgixImage('/images/empty-product.png');
  const {userLocal} = useGlobalContext();
  const [currentImage, setcurrentImage] = useState(
    img ? generateImageUrl(img) : emptyProduct
  );

  const handleChangeColor = variant => {
    const image = variant.images[0]
      ? generateImageUrl(variant.images[0].product_url)
      : emptyProduct;
    setcurrentImage(image);
  };

  const isBackorderable = product?.variants.some(
    item => item.is_backorderable === true
  );
  const isInStock = product?.variants.some(item => item.total_on_hand >= 1);

  const stockStatus = isInStock
    ? 'IN STOCK'
    : isBackorderable
    ? product.available_on
      ? `AVAILABLE ON: ${moment(product?.available_on).format('MMM DD/YYYY')}`
      : 'PRE-ORDER'
    : 'OUT OF STOCK';

  return (
    <StyledCard imageMain={currentImage}>
      <Link to={`/products/${productId}`}>
        <div className="wrap-card">
          <div className="wrap-card-image">
            <div className="image-main"></div>
            <Hidden xs sm>
              {buttonText && <Button>{buttonText}</Button>}
              <div className="container-colorpicker">
                <ColorPicker
                  product={product}
                  productId={productId}
                  onChangeColor={handleChangeColor}
                />
              </div>
            </Hidden>
          </div>

          <div className="wrap-card-information">
            <Text as="h5" className="wrap-card-information-name-product">
              {productName}
            </Text>
            {!userLocal ? '' : <Heading as="h5">{Price}</Heading>}
            <Text as="h5">{stockStatus}</Text>
          </div>
        </div>
      </Link>
    </StyledCard>
  );
};

export default Card;

Card.propTypes = {
  product: PropTypes.shape({
    master: PropTypes.shape({
      images: PropTypes.array,
    }),
    variants: PropTypes.array,
    available_on: PropTypes.string,
  }),
  img: PropTypes.element,
  productId: PropTypes.string,
  buttonText: PropTypes.string,
  productName: PropTypes.string,
  currentImage: PropTypes.string,
  Price: PropTypes.string,
};
