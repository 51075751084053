import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

import arrowBotton from '../../images/arrow-botton-right.png';

export const CollectionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position-x: center;
  padding: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  padding-left: 0px;
  ${props => config(props).media['sm']`
    margin-bottom: 40px;
  `}
  h3 {
    margin-bottom: 29px;
    margin-top: 30px;
    margin-left: 16px;
    ${props => config(props).media['sm']`
      margin-left: 36px;
    `}
  }
  .slick-slider {
    .slick-list {
      border-radius: 21px;
      .slick-slide {
        > div {
          width: 100%;
        }
      }
    }

    button.slick-prev {
      display: flex;
      position: absolute;
      background: url(${arrowBotton}) center center no-repeat;
      transform: rotate(0deg);
      height: 0px;
      width: 0px;
      z-index: 1;
      top: 380px;
      left: 76%;
      ${props => config(props).media['sm']`
        left: 85%;
        height: 40px;
        width: 40px;
      `}
      ${props => config(props).media['md']`
        left: 82%;
      `}
      ${props => config(props).media['lg']`
        left: 87%;
      `}
    }
    button.slick-next {
      display: flex;
      position: absolute;
      background: url(${arrowBotton}) center center no-repeat;
      transform: rotate(180deg);
      height: 0px;
      width: 0px;
      z-index: 1;
      top: 380px;
      left: 88%;
      ${props => config(props).media['sm']`
        left: 92%;
        height: 40px;
        width: 40px;
      `}
      ${props => config(props).media['md']`
        left: 90%;
      `}
      ${props => config(props).media['lg']`
        left: 93%;
      `}
    }

    button:before {
      display: none;
    }

    .slick-dots {
      li button:before {
        display: flex;
        font-size: 18px;
        width: 8px;
        height: 8px;
        content: '';
        top: -45px;
        border: 0.5px solid white;
        border-radius: 50%;
        opacity: 1;
      }
      li.slick-active button:before {
        font-size: 10px;
        opacity: 1;
        content: '';
        background-color: white;
      }
    }
  }
`;
