import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from 'react';
import {
  useQueryParams,
  ArrayParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import PropTypes from 'prop-types';

import {setOrder} from './services/temporalOrder';
import {getCurrentOrder} from './API/makers/orders';
import {getCurrentUser, getUser, isLoggedIn} from './services/authentication';
import {
  addFavorites,
  deleteFavorites,
  getFavorites,
} from './API/makers/favorite';

export const AppContext = createContext();

export const AppProvider = ({children}) => {
  const [filterStyle, setFilterStyle] = useState('');
  const [displayVariant, setDisplayVariant] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [queryFilter, setQueryFilter] = useQueryParams({
    style: withDefault(ArrayParam, []),
    size: withDefault(ArrayParam, []),
    price: withDefault(ArrayParam, []),
    // order_by: withDefault(ArrayParam, []),
    order_by: withDefault(ArrayParam, [
      {
        label: 'Most Recent Desc',
        value: 'created_at_desc',
      },
    ]),
    page: withDefault(NumberParam, 1),
    per_page: withDefault(NumberParam, 100),
  });
  const getStyleOption = (style = '') => {
    const [value, label] = style.split(',');
    return {label, value: Number(value)};
  };
  const [filter, setFilter] = useState({
    style: queryFilter.style.map(getStyleOption),
    size: queryFilter.size.map(size => ({label: size, value: size})),
    price: queryFilter.price.map(price => ({label: price, value: price})),
    order_by: queryFilter.order_by.map(order_by => ({
      label: order_by.label,
      value: order_by.value,
    })),
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSizeIsOpen, setModalSizeIsOpen] = useState(false);
  const [modalPopupsIsOpen, setModalPopupsIsOpen] = useState(false);
  const [userLocal, setUserLocal] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [itemSelected, setItemSelected] = useState({});
  const [countResult, setCountResult] = useState(0);
  const [isTrending, setIsTrending] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [listFavoriteId, setListFavoriteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {favorite_products = []} = useMemo(
    () => listFavoriteId || {},
    [listFavoriteId]
  );
  const fetchCurrentOrder = () => {
    getCurrentOrder(getCurrentUser()['Access-Token'], order =>
      order ? setCurrentOrder(order) : setCurrentOrder(null)
    );
  };
  const refreshUser = () => {
    isLoggedIn() ? setUserLocal(getUser()) : setUserLocal(null);
  };
  const getNumberCurrentOrder = () => {
    currentOrder && setOrder(currentOrder.number);
  };
  const getListFavorites = () => {
    getFavorites(
      getCurrentUser()['Access-Token'],
      favorite_products =>
        favorite_products && setListFavoriteId(favorite_products)
    );
  };
  const getProduct_id = id => {
    const product_id = favorite_products?.find(
      favorite_products => favorite_products.product_id === id
    );
    return product_id;
  };
  const sendToFavorites = id => {
    addFavorites(
      {
        user: getCurrentUser()['Access-Token'],
        product_id: id,
      },
      () => {
        getListFavorites();
      }
    );
  };
  const deleteOnListFavorite = product_id => {
    const favorite_products = listFavoriteId?.favorite_products;
    const favoritesId = favorite_products.find(
      favorite_products => favorite_products.product_id === product_id
    );
    favoritesId &&
      deleteFavorites(
        {
          user: getCurrentUser()['Access-Token'],
          id: favoritesId.id,
        },
        () => {
          getListFavorites();
        }
      );
  };
  const handleChangeOnFavorite = id => {
    !getProduct_id(id) ? sendToFavorites(id) : deleteOnListFavorite(id);
  };
  useEffect(() => {
    refreshUser();
    userLocal !== null && fetchCurrentOrder();
  }, []);
  useEffect(() => {
    userLocal !== null && fetchCurrentOrder();
  }, [userLocal]);
  useEffect(() => {
    (currentOrder?.state && currentOrder?.state === 'cart') ||
      currentOrder?.state !== 'address' ||
      currentOrder?.state !== 'delivery' ||
      currentOrder?.state !== 'payment' ||
      currentOrder?.state !== 'confirm' ||
      fetchCurrentOrder();
  }, [currentOrder?.state]);
  useEffect(() => {
    isLoggedIn() && !listFavoriteId && getListFavorites();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        filterStyle,
        setFilterStyle,
        displayVariant,
        setDisplayVariant,
        modalIsOpen,
        setModalIsOpen,
        refreshUser,
        userLocal,
        setUserLocal,
        username,
        setUsername,
        password,
        setPassword,
        itemSelected,
        setItemSelected,
        getNumberCurrentOrder,
        modalSizeIsOpen,
        setModalSizeIsOpen,
        countResult,
        setCountResult,
        isTrending,
        setIsTrending,
        fetchCurrentOrder,
        currentOrder,
        queryFilter,
        setQueryFilter,
        filter,
        setFilter,
        getStyleOption,
        disableBtn,
        setDisableBtn,
        getListFavorites,
        listFavoriteId,
        favorite_products,
        sendToFavorites,
        deleteOnListFavorite,
        handleChangeOnFavorite,
        getProduct_id,
        modalPopupsIsOpen,
        setModalPopupsIsOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
AppProvider.propTypes = {
  children: PropTypes.node,
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};
