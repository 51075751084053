import React from 'react';
import {Col, Container, Hidden, Row} from 'react-awesome-styled-grid';
import {Heading} from 'styled-typography';
import {StyledProductDetails} from './styles';
import Skeleton from 'react-loading-skeleton';

const SkeletonDetails = () => {
  return (
    <StyledProductDetails>
      <Container>
        <Row className="links-routes">
          <Heading as="h4" level="6">
            <Skeleton width={200} height={30} />
          </Heading>
        </Row>
        <Row>
          <Hidden xs sm>
            <Col>
              <Skeleton width={200} height={500} />
            </Col>
          </Hidden>
          <Col align="center">
            <Skeleton width={400} height={500} />
          </Col>
          <Col sm="8" md="3.3" lg="5.8">
            <div className="add-products-wrapper">
              <Heading as="h3" level="3">
                <Skeleton width={150} height={50} />
              </Heading>
              <Skeleton width={50} height={50} circle={true} />
            </div>
            <div>
              <Heading as="h5" level="6" color="#9B9FA8">
                <Skeleton width={100} height={30} />
              </Heading>
            </div>
            <div className="price-wrapper">
              <Heading as="h3" level="3">
                <Skeleton width={100} height={50} />
              </Heading>
              <Heading as="h3" level="3" color="#D7D9DC">
                <Skeleton width={100} height={50} />
              </Heading>
            </div>

            <Skeleton width={800} height={40} />
            <Hidden xs sm>
              <div>
                <Skeleton width={600} height={30} />
              </div>
            </Hidden>
          </Col>
        </Row>
        <Row md="8" justify="space-between">
          <Col md="3.8" lg="5.9">
            <div className="head-info">
              <Heading as="h3" level="6">
                <Skeleton height={30} />
              </Heading>
            </div>
            <div>
              <Skeleton width={600} height={50} />
            </div>
            <div className="list-info">
              <Skeleton width={300} height={100} />
            </div>
          </Col>
          <Col md="3.8" lg="5.9">
            <div className="head-info">
              <Heading as="h3" level="6">
                <Skeleton height={30} />
              </Heading>
            </div>
            <div>
              <Skeleton width={700} height={60} />
            </div>
            <div className="list-info">
              <Skeleton width={300} height={100} />
            </div>
          </Col>
        </Row>
      </Container>
    </StyledProductDetails>
  );
};

export default SkeletonDetails;
