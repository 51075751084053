import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {Col, Hidden, Row} from 'react-awesome-styled-grid';
import ReactImageMagnify from '@blacklab/react-image-magnify';
import React, {useEffect, useRef, useState, useMemo} from 'react';

import {StyledSlider} from './styles';
import {useGlobalContext} from '../../../context';
import useImgixImage from '../../../hooks/useImgixImage';
import {generateImageUrl} from '../../../utils/productHelper';

export const DoubleSlider = ({product, isLoading}) => {
  const arrow = useImgixImage('/images/icon/arrows/arrow.png');
  const arrowUp = useImgixImage('/images/icon/arrows/arrow-up.png');
  const arrowDown = useImgixImage('/images/icon/arrows/arrow-down.png');
  const imgEmptySmall = useImgixImage('/images/no-image.png', {
    w: 70,
    h: 76,
  });
  const imgEmpty = useImgixImage('/images/no-image.png');
  const {displayVariant} = useGlobalContext();
  const images = useMemo(() => {
    if (!Object.keys(product).length) return [];
    const productsVariants = [product.master, ...product.variants];
    const images =
      displayVariant === ''
        ? productsVariants
            .map(item => item.images)
            .flat()
            .map(item => ({
              ...item,
              small_url: generateImageUrl(item.small_url),
              product_url: generateImageUrl(item.product_url),
              large_url: generateImageUrl(item.large_url),
            }))
        : productsVariants
            .find(variant => variant.id === displayVariant)
            ?.images.map(item => ({
              ...item,
              small_url: generateImageUrl(item.small_url),
              product_url: generateImageUrl(item.product_url),
              large_url: generateImageUrl(item.large_url),
            }));
    return images && images.length > 0
      ? images
      : [{small_url: imgEmptySmall, id: -1, product_url: imgEmpty}];
  }, [product, displayVariant]);

  const settings = {
    dots: false,
    infinite: true,
    // centerPadding: '40px',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4500,
    rtl: false,
    arrows: true,
  };

  const settingsTwo = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    centerPadding: '0px',
    focusOnSelect: true,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
  };

  const nav1 = useRef(null);
  const nav2 = useRef(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setSlider1(nav1.current);
      setSlider2(nav2.current);
    }, 1000);
  }, []);

  const next = () => {
    slider2.slickNext();
  };

  const previous = () => {
    slider2.slickPrev();
  };

  return (
    <StyledSlider arrow={arrow}>
      <Row justify="space-between">
        {product.variants && (
          <Hidden xs sm>
            <Col md="2" lg="2">
              <div className="Bottom" onClick={() => previous()}>
                <img src={arrowUp} />
              </div>
              <div className="card-wrapper-two">
                <Slider
                  asNavFor={slider1}
                  ref={nav2}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  {...settingsTwo}
                >
                  {isLoading ? (
                    <p>Loading</p>
                  ) : (
                    images.map((image, id) => (
                      <div key={id}>
                        <img src={image.small_url} />
                      </div>
                    ))
                  )}
                </Slider>
              </div>
              <div className="Bottom" onClick={() => next()}>
                <img src={arrowDown} />
              </div>
            </Col>
          </Hidden>
        )}

        <Col xs="4" sm="4" md="6" lg="9.4">
          <div className="card-wrapper-one">
            <Slider asNavFor={slider2} ref={nav1} {...settings}>
              {isLoading ? (
                <p>Loading</p>
              ) : (
                images.map((image, id) => (
                  <div key={id}>
                    <Hidden xs sm>
                      <ReactImageMagnify
                        imageProps={{
                          isFluidWidth: true,
                          src: image.large_url,
                          width: '100%',
                        }}
                        magnifiedImageProps={
                          image.attachment_height > 620
                            ? {
                                src: image.large_url,
                                width: 700,
                                height: 1000,
                                display: 'flex',
                              }
                            : {
                                src: image.large_url,
                                width: 900,
                                height: 700,
                                display: 'flex',
                              }
                        }
                        portalProps={{
                          placement: 'over',
                          id: 'portal-test-id',
                        }}
                        // magnifyContainerProps={{
                        //   height: 500,
                        //   width: 500,
                        //   className: 'wrap-zoom',
                        // }}
                      />
                    </Hidden>

                    <Hidden md lg xl>
                      <Col align="center">
                        <img src={image.product_url} className="image-small" />
                      </Col>
                    </Hidden>
                  </div>
                ))
              )}
            </Slider>
          </div>
        </Col>
      </Row>
    </StyledSlider>
  );
};

export default DoubleSlider;

DoubleSlider.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    master: PropTypes.array,
    variants: PropTypes.array,
    product_url: PropTypes.string,
    images: PropTypes.shape({
      mini_url: PropTypes.string,
      small_url: PropTypes.string,
    }),
  }),
  isLoading: PropTypes.bool,
};
