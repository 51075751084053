import PropTypes from 'prop-types';
import {navigate} from 'gatsby-link';
import React, {useState} from 'react';
import {Link, Text} from 'styled-typography';

import {ModalStyle} from './styles';
import InputForm from '../core/InputForm';
import ButtonBlack from '../core/ButtonBlack';
import {signIn} from '../../API/makers/users';
import {useGlobalContext} from '../../context';
import useImgixImage from '../../hooks/useImgixImage';

const ModalLogin = () => {
  const [useInvalid, setUseInvalid] = useState(false);
  const iconClose = useImgixImage('/images/close-icon.svg');
  const {
    setModalIsOpen,
    setPassword,
    password,
    setUsername,
    username,
    refreshUser,
    fetchCurrentOrder,
  } = useGlobalContext();

  const handleLogin = () => {
    signIn({email: username, password: password}, () => {
      closeModal();
      refreshUser();
      fetchCurrentOrder();
    }).catch(changeUseInvalid);
  };

  const changeUseInvalid = () => {
    setUseInvalid(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    navigate('/');
  };
  const handleToRegister = () => {
    navigate('/register?step=1');
    closeModal;
  };
  const handleToForgotPassword = () => {
    navigate('/reset-password');
    closeModal;
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleLogin();
    }
    if (e.charCode == 13) {
      handleLogin();
    }
    if (e.keyCode == 13) {
      handleLogin();
    }
  };

  return (
    <ModalStyle>
      <div className="title-wrapper">
        <h2>Log in</h2>

        <img src={iconClose} onClick={closeModal} />
      </div>
      <Text level="5" as="h3">
        Enter the following information to enter your account
      </Text>
      {useInvalid === true && (
        <span className="invalid"> Incorrect username or password</span>
      )}
      <form onKeyPress={handleKeyPress}>
        <Text level="6" as="h2">
          Email
        </Text>
        <InputForm
          value={username}
          placeholder="Username"
          type="e-mail"
          onChange={e => setUsername(e.target.value)}
        />
        <Text level="6" as="h2">
          Password ·
          <span onClick={handleToForgotPassword}> Forgot password? </span>
        </Text>
        <InputForm
          value={password}
          placeholder="Password"
          type="password"
          onChange={e => setPassword(e.target.value)}
        />
        <label>
          <input type="checkbox" id="accept" name="accept" value="checked" />
          Remember my account
        </label>
        {/* <label> */}
        {/* <input type="submit" value="LOG IN" /> */}
        {/* <ButtonBlack type="submit" width="100%" onClick={handleLogin}>
          LOG IN
        </ButtonBlack> */}
        {/* </label> */}
      </form>
      <div>
        <ButtonBlack width="100%" onClick={handleLogin}>
          LOG IN
        </ButtonBlack>
      </div>
      <Text level="5" as="h5">
        Do not you have an account yet?
      </Text>
      <Link onClick={handleToRegister}>
        <span>Create one here</span>
      </Link>
    </ModalStyle>
  );
};

export default ModalLogin;

ModalLogin.propTypes = {
  subtitle: PropTypes.string,
};
