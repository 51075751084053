import * as React from 'react';
import PropTypes from 'prop-types';

import {StyledButtonIcon} from './styles';
import {Text} from 'styled-typography';

const ButtonIcon = ({iconHover, icon, number, onClick, borderColor}) => {
  return (
    <StyledButtonIcon borderColor={`${borderColor}`} onClick={onClick}>
      <img src={icon} onMouseOver={iconHover} />
      <Text>{number}</Text>
    </StyledButtonIcon>
  );
};

export default ButtonIcon;

ButtonIcon.defaultProps = {
  borderColor: '#d7d9dc',
};

ButtonIcon.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.element,
  iconHover: PropTypes.element,
  number: PropTypes.string,
  onClick: PropTypes.func,
  borderColor: PropTypes.string,
};
