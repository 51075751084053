import styled from 'styled-components';

export const StyledButtonBlack = styled.button`
  width: ${({width}) => width};
  height: ${({height}) => height};
  background-color: ${({theme}) => theme.colors.primary};
  padding: 0;
  border-radius: 6px;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
  transition: all 1s ease-in-out;

  :before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    border-radius: 6px;
    background: rgba(205, 173, 115, 0.2);
  }

  p {
    color: white;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &:hover {
    position: relative;
    transition: background-color 0.5s ease-in-out;
    :before {
      position: absolute;
      width: 100%;
    }
    background-color: ${({theme}) => theme.colors.primary2};
  }

  &:active {
    outline: none;
    background-color: ${({theme}) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({theme}) => theme.colors.secondary};
    cursor: default;
  }
`;
