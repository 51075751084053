import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const ColorPickerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.11);
  background-color: white;
  height: 18px;
  border-radius: 12px;

  ${props => config(props).media['sm']`
    display:none;
    top: 316px;
    left: 85px;
    height: 24px;
  `}
  ${props => config(props).media['md']`
    display:flex;
    top: 205px;
    left: 40px;
    height: 24px;
  `}
  ${props => config(props).media['lg']`
  display:flex;
    top: 316px;
    left: 85px;
    height: 24px;
  `}
  
  h2 {
    color: ${({theme}) => theme.colors.secondary2};
    font-weight: 800;
    margin-right: 8px;
    margin-left: 8px;
  }
  h3 {
    color: ${({theme}) => theme.colors.secondary2};
    font-weight: 800;
    font-size: 16px;
    margin-right: 8px;
    margin-left: 8px;
    :hover {
      color: ${({theme}) => theme.colors.secondary};
    }
  }
  .circle-more-colors {
    display: flex;
    margin-right: 20px;
    border-radius: 12px;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    h4 {
      color: ${({theme}) => theme.colors.primary};
      font-weight: 500;
      padding-bottom: 2px;
      :hover {
        color: ${({theme}) => theme.colors.primary2};
      }
    }
    :hover {
      cursor: pointer;
      color: ${({theme}) => theme.colors.primary2};
    }
  }
`;

export const CircleColor = styled.div`
  background-color: ${({color}) => color.name};
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  width: 5px;
  height: 5px;
  margin-right: 2px;
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${props => config(props).media['sm']`
      width: 10px;
      height: 10px;
  `}
  :hover {
    border: 2px solid #c4c4c4;
  }
  :first-child {
    margin-left: 20px;
  }
  :last-child {
    margin-right: 20px;
  }
`;
