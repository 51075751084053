import * as React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';

import {StyledCardCategory} from './styles';

const CardCategory = ({background, name, height}) => {
  return (
    <StyledCardCategory background={background} height={height}>
      <div>
        <Text>{name}</Text>
      </div>
    </StyledCardCategory>
  );
};

export default CardCategory;

CardCategory.propTypes = {
  img: PropTypes.element,
  background: PropTypes.element,
  name: PropTypes.string,
  height: PropTypes.string,
};
