import styled from 'styled-components';

export const StyledButtonIcon = styled.button`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 26px;
  border: 1.5px solid ${({borderColor}) => borderColor};
  cursor: pointer;
  background-color: transparent;

  p {
    font-size: ${({theme}) => theme.typography.fontSizes[5]};
    font-weight: 800;
    color: white;
  }

  &:hover {
    border: 1.5px solid ${({theme}) => theme.colors.primary4};
  }

  &:active {
    border: 1.5px solid ${({theme}) => theme.colors.primary4};
  }

  &:focus {
    border: 1.5px solid ${({theme}) => theme.colors.primary3};
  }
`;
