import * as React from 'react';
import {StyledCardCollections} from './styles';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import {Link} from 'gatsby';

const CardCollections = ({background}) => {
  return (
    <StyledCardCollections background={background}>
      <div>
        <Heading as="h4" level="3">
          Trending search on Maker’s
        </Heading>
        <div className="line" />
        <div className="container-wrapper">
          <span>
            <Link to={'/search-page?page=1&per_page=8&search=summer'}>
              <Text>summer</Text>
            </Link>
          </span>
          <span>
            <Link to={'/all-shoes?style=8,sandals'}>
              <Text>sandals</Text>
            </Link>
          </span>

          <span>
            <Link to={'/all-shoes?style=4,sneakers'}>
              <Text>sneakers</Text>
            </Link>
          </span>
        </div>
        <div className="container-wrapper">
          <span>
            <Link to={'/all-shoes?style=9,flats'}>
              <Text>flats</Text>
            </Link>
          </span>
          <span>
            <Link to={'/all-shoes?style=6,heels'}>
              <Text>heels</Text>
            </Link>
          </span>

          <span>
            <Link to={'/all-shoes?style=5,boots'}>
              <Text>boots</Text>
            </Link>
          </span>
        </div>
        <div className="container-wrapper">
          <span>
            <Link to={'/all-shoes?style=7,wedges'}>
              <Text>wedges</Text>
            </Link>
          </span>
          <span>
            <Link to={'/search-page?page=1&per_page=8&search=beach'}>
              <Text>beach</Text>
            </Link>
          </span>
        </div>

        <div className="line" />
      </div>
    </StyledCardCollections>
  );
};

export default CardCollections;

CardCollections.propTypes = {
  background: PropTypes.element,
  name: PropTypes.string,
};
