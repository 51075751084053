import * as React from 'react';
import {FontWeight, Text} from 'styled-typography';
import PropTypes from 'prop-types';

import {StyledButtonBlack} from './styles';

const ButtonBlack = ({children, ...props}) => {
  return (
    <StyledButtonBlack {...props}>
      <Text level="5" fontWeight={FontWeight.Light}>
        {children}
      </Text>
    </StyledButtonBlack>
  );
};

ButtonBlack.propTypes = {
  children: PropTypes.node,
};

ButtonBlack.defaultProps = {
  width: '120px',
  height: '42px',
};

export default ButtonBlack;
